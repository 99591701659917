import React, { Component } from 'react';
import _, { get } from 'lodash';
import uuid from 'uuid/v4';
import { withRouter } from 'react-router-dom';
import {
    Button,
    Dropdown,
    Form,
    Grid,
    Input,
    Menu,
    Message,
    Radio,
    Segment,
} from 'semantic-ui-react';

import TextInput from 'atoms/TextInput/TextInput';
import Checkbox from 'atoms/Checkbox';
import Tooltip from 'atoms/Tooltip';
import ColoredText from 'atoms/ColoredText';
import copyToClipboard from 'shared/copyToClipboard';

import { getRadioQuestionOptions } from '../../util/radioQuestion';
import RoleManager from '../RoleManager';
import RuleManager from '../RuleManager/RuleManager';
import NodeMetadata, { nodeTypes } from '../../nodeMetadata';
import uiFieldTypes, { uiTypes } from './uiFieldTypes';
import FieldList from './FieldList';
import { getFlagValue } from 'http/featureFlags';

import './FieldManager.css';
import HintManager from '../HintManager/HintManager';
import DataManager from '../HintManager/DataManager';

const TAB_FIELD     = 'field';
const TAB_VISRULES  = 'visrules';
const TAB_VALRULES  = 'valrules';
const TAB_ROLES     = 'roles';
const TAB_PRINT     = 'print';
const TYPE_VALRULE  = 'validationRules';
const TYPE_VISRULE  = 'visibilityRules';
const DEFAULT_LABEL = 'Nyt felt';

export const TYPE_NODE           = 'node';
export const TYPE_RESOURCE       = 'resource'; // corrosponds to field templates
export const TYPE_RESOURCE_TABLE = 'resourceTable';

class FieldManager extends Component {
    constructor (props) {
        super(props);
        this.state = {
            fields: props.fields || [],
            currentField: this.getDefaultSelectedField(),
            nodeFilter: '',
            selectedTab: TAB_FIELD,
            HINT_SECTION: false
        };
    }

    componentDidMount = async () => {
        const hintSectionFeatureFlag = await getFlagValue('HINT_SECTION');
        this.setState({
            HINT_SECTION: hintSectionFeatureFlag
        });

        if (!this.state.currentField) {
            return;
        }

        this.scrollToField(this.state.currentField.id);
    };

    componentDidUpdate = prevProps => {
        const getHash = props => props.location?.hash?.replace('#', '');

        const currentHash = getHash(this.props);
        if (!getHash(prevProps) && currentHash) {
            const field = this.state.fields.find(field => field.id === currentHash);
            if (!field) {
                return;
            }

            this.setCurrentField(field, () => {
                this.scrollToField(field.id);
            });
        }
    };

    scrollToField = fieldID => {
        const referencedField = document.getElementById(fieldID);
        if (!referencedField) {
            return;
        }
        
        window.location.hash = '';
        referencedField.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    };

    getDefaultSelectedField = () => {
        const { fields } = this.props;
        const hash = window.location.hash;
        if (hash) {
            return fields.find(f => f.id === hash.slice(1));
        } else {
            return fields && fields[0];
        }
    };

    getCurrentFieldIndex = () => {
        const { fields, currentField } = this.state;
        return fields.indexOf(currentField);
    };

    getFieldIds = () => {
        const { nodeFilter } = this.state;
        const { fieldIds } = this.props;
        return fieldIds.filter(fid => new RegExp(nodeFilter, 'i').test(fid.name));
    };

    setFields = fields => {
        return new Promise(resolve => {
            this.setState({ fields }, () => {
                this.props.onChange(fields);
                resolve();
            });
        });
    };

    setCurrentField = (currentField, callback) => {
        this.setState({ currentField }, () => {
            this.props.onChange(this.state.fields);
            callback && callback();
        });
    };

    getData () {
        return this.state.fields;
    }

    swap = (startIndex, endIndex) => {
        const { fields } = this.state;
        const [removed] = fields.splice(startIndex, 1);
        fields.splice(endIndex, 0, removed);
        this.setFields(fields);
    };

    selectedFieldChanged = index => {
        const { fields } = this.state;

        this.setCurrentField(fields[index]);
    }

    async newField (type, currentIndex) {
        const { fields } = this.state;

        const cleanField = {
            id: uuid(),
            label: DEFAULT_LABEL,
            styleOptions: {},
            visibilityRules: [],
            validationRules: [],
            type
        };
        fields.splice(currentIndex + 1, 0, cleanField);

        await this.setFields(fields);
        this.selectedFieldChanged(currentIndex + 1);
    }

    updateRules = (ruleType, rules) => {
        const { currentField } = this.state;
        currentField[ruleType] = rules;

        this.setCurrentField(currentField);
    }

    updateRoles = (roleID, value) => {
        const { currentField } = this.state;
        
        const roles = new Set(currentField.roles || []);
        value ? roles.add(roleID) : roles.delete(roleID);
        currentField.roles = [...roles];

        this.setCurrentField(currentField);
    }

    // use with events returned by input components
    updateField = (e, propertyPath) => {
        const { currentField } = this.state;

        const updateValue = e.target.value;
        _.set(currentField, propertyPath, updateValue);

        if (propertyPath === 'nodeId') {
            // automatically updates the field label
            // to match selected node name
            if (currentField.label === DEFAULT_LABEL) {
                const node = this.props.fieldIds.find(fi => fi.id === updateValue);
                if (node.name) {
                    _.set(currentField, 'label', node.name);
                }
            }
        }

        this.setCurrentField(currentField);
    };

    setFieldValue = (propertyPath, value) => {
        const { currentField } = this.state;
        _.set(currentField, propertyPath, value);
        this.setCurrentField(currentField);
    };

    deleteField = async fieldIndex => {
        const { fields } = this.state;
        fields.splice(fieldIndex, 1);

        await this.setFields(fields);

        const newFieldIndex = fieldIndex > fields.length - 1 ? fieldIndex - 1 : fieldIndex;
        this.selectedFieldChanged(newFieldIndex);
    };

    gotoNode = async node => {
        const { save, modelId } = this.props;
        if (save) {
            await save();
        }
        this.props.history.push(`/model-editor/${modelId}/node/${node}`);
    };

    renderFieldCreationMenu = () => {
        const selectedFieldIndex = this.getCurrentFieldIndex();
        const { stickyMenu, resourceNodes } = this.props;
        const hasResourceNodes = resourceNodes && resourceNodes.length > 0;
        return (
            <Menu size='tiny' className={stickyMenu ? 'menu-stuck-bottom' : undefined}>
                <Menu.Item>
                    <Button
                        primary
                        size='mini'
                        onClick={() => this.newField(TYPE_NODE, selectedFieldIndex)}
                        icon='info circle'
                        content='Nyt nodefelt'
                    />
                </Menu.Item>
                <Menu.Item>
                    <Button
                        positive
                        basic
                        size='mini'
                        onClick={() => this.newField(uiTypes.HEADER, selectedFieldIndex)}
                        icon='paint brush'
                        content='Nyt UI felt'
                    />
                </Menu.Item>
                {
                    hasResourceNodes && (
                        <Menu.Item>
                            <Button
                                basic
                                color='blue'
                                size='mini'
                                onClick={() => this.newField(TYPE_RESOURCE, selectedFieldIndex)}
                                icon='cog'
                                content='Nyt ressourcefelt'
                            />
                        </Menu.Item>
                    )
                }
                {
                    hasResourceNodes && (
                        <Menu.Item>
                            <Button
                                basic
                                color='blue'
                                size='mini'
                                onClick={() => this.newField(TYPE_RESOURCE_TABLE, selectedFieldIndex)}
                                icon='table'
                                content='Ny ressourcetabel'
                            />
                        </Menu.Item>
                    )
                }
            </Menu>
        );
    };

    renderFieldList = () => {
        const { fields, HINT_SECTION } = this.state;
        const selectedFieldIndex = this.getCurrentFieldIndex();

        return (
            <Grid.Column>
                <Form className='wideForm'>
                    <Form.Field>
                        <FieldList
                            printable={this.props.printable}
                            fields={fields}
                            resourceNodes={this.props.resourceNodes}
                            onChange={this.selectedFieldChanged}
                            swap={this.swap}
                            index={selectedFieldIndex}
                            deleteField={this.deleteField}
                            fieldIDs={this.props.fieldIds}

                            // TODO: Remove when feature flag is mature
                            HINT_SECTION={HINT_SECTION}
                        />
                    </Form.Field>
                </Form>
            </Grid.Column>
        );
    };

    renderSelectedFieldInfo = () => {
        const { currentField, selectedTab } = this.state;
        if (!currentField) {
            return null;
        }

        const createMenuItem = (name, icon, prop) => {
            return (
                <Menu.Item
                    name={name}
                    active={selectedTab === prop}
                    icon={icon}
                    onClick={() => this.setState({ selectedTab: prop })}
                />
            );
        };

        return (
            <Grid.Column>
                <Form key={currentField.id} className='field-info-form wideForm' style={{ top: this.props.stickyOffset || '35px' }} >
                    <Menu pointing>
                        {createMenuItem('Feltdata', 'dna', TAB_FIELD)}
                        {createMenuItem('Synlighedsregler', 'eye', TAB_VISRULES)}
                        {createMenuItem('Valideringsregler', 'flag checkered', TAB_VALRULES)}
                        {createMenuItem('Roller', 'users', TAB_ROLES)}
                        {createMenuItem('Print', 'print', TAB_PRINT)}
                    </Menu>
                    {this.renderSelectedFieldTab()}
                </Form>
            </Grid.Column>
        );
    };

    renderRolesTab = () => {
        const { currentField } = this.state;
        return <Segment>
            <Checkbox
                toggle
                label='Exclude if has role?'
                onChange={e => this.updateField(e, 'rolesShouldExclude')}
                checked={currentField.rolesShouldExclude}
            />
            <RoleManager
                onChange={(id, value) => this.updateRoles(id, value)}
                selectedRoles={this.state.currentField.roles}
            />
        </Segment>;
    };

    renderPrintTab = () => {
        const sectionIsPrintable = this.props.printable;

        if (!sectionIsPrintable) {
            return <Message
                color='blue'
                content='Aktiver print på sektionen for at angive printindstillinger'
            />;
        }

        const { printOptions } = this.state.currentField;

        return <Segment>
            <Form style={{ width: '100%' }}>
                <Form.Field>
                    <label>Inkluder felt i print?</label>
                    <Checkbox
                        toggle
                        onChange={e => this.updateField(e, 'printOptions.shouldPrint')}
                        checked={get(printOptions, 'shouldPrint')}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Printlabel</label>
                    <Input
                        placeholder='Alternativt label i udskrift'
                        onChange={e => this.updateField(e, 'printOptions.label')}
                        defaultValue={get(printOptions, 'label')}
                    />
                </Form.Field>
            </Form>
        </Segment>
    };

    renderSelectedFieldTab = () => {
        switch (this.state.selectedTab) {
            case TAB_FIELD:
                return this.renderFieldOptionsTab();
            case TAB_VISRULES:
                return this.renderVisibilityRuleTab();
            case TAB_VALRULES:
                return this.renderValidationRuleTab();
            case TAB_ROLES:
                return this.renderRolesTab();
            case TAB_PRINT:
                return this.renderPrintTab();
            default:
                return null;
        }
    };

    getCurrentFieldModeMetadata = () => {
        const { currentField } = this.state;
        const { fieldIds } = this.props;
        if (!currentField || !fieldIds) return ;
        const { nodeId } = currentField;
        if (!nodeId) return ;

        const node = fieldIds.find(n => n.id === nodeId)
        if (!node) return ;
        return NodeMetadata(node.type);
    }

    renderResourceFieldOptions = () => {
        const { currentField } = this.state;
        const { resourceNodes } = this.props;

        const resourceNodeOptions = resourceNodes.map(node => {
            return {
                text: node.name,
                value: node.id,
            };
        });

        const fieldTemplateOptions = [];

        if (currentField.resourceNode) {
            const fullResourceNode = resourceNodes.find(node => {
                return node.id === currentField.resourceNode;
            });
            
            const fieldTemplates = fullResourceNode?.data?.fieldTemplates || [];
            fieldTemplateOptions.push(...fieldTemplates.map(template => {
                return {
                    text: template.name,
                    value: template.name,
                };
            }));
        }

        return <>
            <Form.Field>
                <label>Vælg en ressourcenode:</label>
                <Dropdown
                    selection
                    options={resourceNodeOptions}
                    onChange={(_, { value }) => this.setFieldValue('resourceNode', value)}
                    defaultValue={currentField.resourceNode}
                />
            </Form.Field>
            <Form.Field disabled={!currentField.resourceNode}>
                <label>Vælg en feltskabelon:</label>
                <Dropdown
                    selection
                    options={fieldTemplateOptions}
                    onChange={(_, { value }) => this.setFieldValue('fieldTemplate', value)}
                    defaultValue={currentField.fieldTemplate}
                />
            </Form.Field>
        </>;
    };

    renderResourceTableOptions = () => {
        const { currentField } = this.state;
        const { resourceNodes } = this.props;

        const resourceNodeOptions = resourceNodes.map(node => {
            return {
                text: node.name,
                value: node.id,
            };
        });

        const fieldTemplateOptions = [];

        if (currentField.resourceNode) {
            const fullResourceNode = resourceNodes.find(node => {
                return node.id === currentField.resourceNode;
            });
            
            const tables = fullResourceNode?.data?.tables || [];
            fieldTemplateOptions.push(...tables.map(table => {
                return {
                    text: table.title,
                    value: table.id
                };
            }));
        }

        return <>
            <Form.Field>
                <label>Vælg en ressourcenode:</label>
                <Dropdown
                    selection
                    options={resourceNodeOptions}
                    onChange={(_, { value }) => this.setFieldValue('resourceNode', value)}
                    defaultValue={currentField.resourceNode}
                />
            </Form.Field>
            <Form.Field disabled={!currentField.resourceNode}>
                <label>Vælg en tabel:</label>
                <Dropdown
                    selection
                    options={fieldTemplateOptions}
                    onChange={(_, { value }) => this.setFieldValue('resourceTableID', value)}
                    defaultValue={currentField.resourceTableID}
                />
            </Form.Field>
        </>;
    };

    renderFieldOptionsTab = () => {
        const { currentField } = this.state;
        const { label, tooltip, youtubeID, link, suffix, fileUpload, styleOptions } = currentField;
        const { big, bold, hideIfZero, removeNumberFormatting, direction } = styleOptions;

        if (currentField?.type === TYPE_RESOURCE) {
            return this.renderResourceFieldOptions();
        }

        if (currentField?.type === TYPE_RESOURCE_TABLE) {
            return this.renderResourceTableOptions();
        }

        // grimt hack: vi burde lave en generel løsning til at en/disable elementer pr type
        const nodeMeta = this.getCurrentFieldModeMetadata();
        const currentFieldType = currentField ? currentField.type : undefined;
        const isSegment = currentFieldType === 'segment';
        const disableFieldTooltip = isSegment;
        const tooltipStyle = disableFieldTooltip ? { display: 'none' } : undefined;
        const showStyleOptions = [TYPE_NODE, uiTypes.TEXT].includes(currentFieldType);
        const showSuffix = showStyleOptions;

        // show toolip?
        let showTooltip = true;
        if (currentField) {
            const noTooltipTypes = [
                uiTypes.GROUP_OPEN,
                uiTypes.GROUP_CLOSED,
                uiTypes.GROUP_TOGGLER,
                uiTypes.FREEBIE_GROUP_OPEN,
                uiTypes.FREEBIE_GROUP_CLOSE,
                uiTypes.DIVIDER,
                uiTypes.HEADER,
                uiTypes.SEGMENT,
                uiTypes.AUTOMATED_BOOKING,
                uiTypes.EMBEDDED_VIDEO,
                uiTypes.BOOKING_DISCREPANCY_CHECKER,
            ];

            if (noTooltipTypes.includes(currentField.type)) {
                showTooltip = false;
            }
        }
        if (nodeMeta?.type === nodeTypes.DocumentGenerator) {
            showTooltip = false;
        }

        // show label?
        let showLabel = true;
        const uiFieldType = uiFieldTypes.find(ui => ui.value === currentField?.type);
        if (uiFieldType) {
            showLabel = uiFieldType.hasLabel;
        }
        // grimt hack slut

        return (
            <>
                {
                    (showLabel || showSuffix) &&
                    <Form.Group widths='equal'>
                        {
                            showLabel &&
                            <Form.Field>
                                <label>Label</label>
                                <TextInput onChange={e => this.updateField(e, 'label')} value={label}/>
                            </Form.Field>
                        }
                        { 
                            showSuffix &&
                            <Form.Field>
                                <label>Suffix</label>
                                <TextInput onChange={e => this.updateField(e, 'suffix')} value={suffix}/>
                            </Form.Field>
                        }
                    </Form.Group>
                }
                {
                    showTooltip &&
                    <>
                        <Form.Field style={tooltipStyle}>
                            <label>Tooltip</label>
                            <textarea onChange={e => this.updateField(e, 'tooltip')} value={tooltip}/>
                        </Form.Field>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>YouTube ID</label>
                                <TextInput
                                    placeholder='O6Xo21L0ybE'
                                    value={youtubeID}
                                    onChange={e => this.updateField(e, 'youtubeID')}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Link til fil</label>
                                <TextInput
                                    placeholder='https://i.imgur.com/NZ40Ca3.png'
                                    value={link}
                                    onChange={e => this.updateField(e, 'link')}
                                />
                            </Form.Field>
                        </Form.Group>
                    </>
                }
                { showStyleOptions && <Form.Group widths='equal'>
                    <Form.Field>
                        <label>Felt har filupload?</label>
                        <Checkbox onChange={e => this.updateField(e, 'fileUpload')} checked={fileUpload} toggle/>
                    </Form.Field>
                    <Form.Field>
                        <label>Big</label>
                        <Checkbox onChange={e => this.updateField(e, 'styleOptions.big')} checked={big} toggle/>
                    </Form.Field>
                    <Form.Field>
                        <label>Bold</label>
                        <Checkbox onChange={e => this.updateField(e, 'styleOptions.bold')} checked={bold} toggle/>
                    </Form.Field>
                    <Form.Field>
                        <label>Vis spørgsmål vandret</label>
                        <Checkbox onChange={e => this.updateField(e, 'styleOptions.direction')} checked={direction} toggle/>
                    </Form.Field>
                    <Form.Field>
                        <label>Skjul hvis 0</label>
                        <Checkbox onChange={e => this.updateField(e, 'styleOptions.hideIfZero')} checked={hideIfZero} toggle/>
                    </Form.Field>
                    <Form.Field>
                        <label>Fjern talformatering</label>
                        <Checkbox
                            onChange={e => this.updateField(e, 'styleOptions.removeNumberFormatting')}
                            checked={removeNumberFormatting}
                            toggle
                        />
                    </Form.Field>
                </Form.Group> }
                {this.renderFieldSpecificFields()}
                {this.renderFieldTypeSection()}
                {this.renderFieldIdCopyButton()}
            </>
        );
    };

    renderVisibilityRuleTab = () => {
        const { type } = this.state.currentField;
        if (type === TYPE_RESOURCE) {
            return (
                <Message
                    color='blue'
                    icon='info circle'
                    header='Ressourcefelter kan ikke have synlighedsregler'
                />
            );
        }

        const label = 'Har felt synlighedsregler?';
        const tooltip = 'Alle feltets regler skal være opfyldt før det vises til brugeren.';
        const hasRulesProp = 'hasVisibilityRules';
        const rulesProp = TYPE_VISRULE;
        return (
            <Form.Field>
                {this.renderRulesHeader({
                    label,
                    tooltip,
                    hasRulesProp,
                })}
                {this.renderRules(hasRulesProp, rulesProp)}
            </Form.Field>
        );
    };

    renderValidationRuleTab = () => {
        const { type } = this.state.currentField;
        if (type !== TYPE_NODE) {
            return (
                <Message
                    color='blue'
                    icon='info circle'
                    header='Kun nodefelter kan have valideringsregler'
                />
            );
        }

        const label = 'Har felt valideringsregler?';
        const tooltip = 'Definér regler for hvornår feltets data er godkendt. Ved brud på en regel, vises reglens vejledende besked til brugeren.';
        const hasRulesProp = 'hasValidationRules';
        const rulesProp = TYPE_VALRULE;
        return (
            <Form.Field>
                {this.renderRulesHeader({
                    label,
                    tooltip,
                    hasRulesProp,
                })}
                {this.renderRules(hasRulesProp, rulesProp)}
            </Form.Field>
        );
    };

    renderRulesHeader = ({ hasRulesProp, label, tooltip }) => {
        const { currentField } = this.state;
        const { id } = currentField;
        const hasRules = currentField[hasRulesProp];
        return (
            <Segment key={hasRulesProp}>
                <Checkbox
                    checked={hasRules}
                    key={id}
                    onChange={e => this.updateField(e, hasRulesProp)}
                    type='checkbox'
                    slider
                    label={label}
                />
                &nbsp;
                <Tooltip icon='question circle outline' content={tooltip} size='large' />
            </Segment>
        );
    };

    renderRules = (hasRulesProp, rulesProp) => {
        const { modelId } = this.props;
        const { currentField } = this.state;
        const { id } = currentField;
        const hasRules = currentField[hasRulesProp];
        const rules = currentField[rulesProp];
        if (!hasRules) {
            return null;
        }

        // for getting radio question options
        let getOptionsByID;
        if (this.props.getOptionsByID) {
            getOptionsByID = this.props.getOptionsByID;
        } else {
            getOptionsByID = nodeID => {
                return getRadioQuestionOptions(modelId, nodeID);
            };
        }

        return (
            <RuleManager
                key={`${id}-${rulesProp}`}
                nodes={this.props.nodes}
                rules={rules}
                modelId={this.props.modelId}
                fieldID={id}
                disabled={!hasRules}
                deliverData={r => this.updateRules(rulesProp, r)}
                getOptionsByID={getOptionsByID}
                showAdvisoryMessage={rulesProp === TYPE_VALRULE}
            />
        );
    };

    renderFieldTypeSection = () => {
        const { currentField } = this.state;
        if (currentField && currentField.type !== TYPE_NODE) {
            return this.renderUITypeSection();
        } else {
            return this.renderConnectedNodeSection();
        }
    };

    renderFieldIdCopyButton = () => {
        return (
            <ColoredText
                onClick={() => copyToClipboard(this.state.currentField.id)}
                icon='copy'
                iconPosition='left'
                content='Kopiér felt ID'
                link
                underlined={false}
            />
        );
    };

    renderFieldSpecificFields = () => {
        const { currentField, HINT_SECTION } = this.state;
        if (!currentField) {
            return;
        }

        // --------------------------------------------------

        let fieldSpecificFields = null;
        switch(currentField.type) {
            case uiTypes.DIVIDER: {
                fieldSpecificFields = this.renderUIDividerFields();
                break;
            }
            case uiTypes.SEGMENT: {
                fieldSpecificFields = this.renderUISegmentFields();
                break;
            }
            case uiTypes.GROUP_OPEN: {
                fieldSpecificFields = this.renderUIGroupOpenFields();
                break;
            }
            case uiTypes.FREEBIE_GROUP_OPEN: {
                fieldSpecificFields = this.renderUIFreebieGroupOpenFields();
                break;
            }
            case uiTypes.AUTOMATED_BOOKING: {
                fieldSpecificFields = this.renderUIAutomatedBookingField();
                break;
            }
            case uiTypes.TEXT: {
                fieldSpecificFields = this.renderTextSpecificFields();
                break;
            }
            case uiTypes.EMBEDDED_VIDEO: {
                fieldSpecificFields = this.renderEmbeddedVideoSpecificFields();
                break;
            }
            case TYPE_NODE: {
                fieldSpecificFields = this.renderNodeFields();
                break;
            }
            default: {
                console.error(`Could not render fields of field type '${currentField.type}'`);
            }
        }

        // --------------------------------------------------

        /* We want to make it possible to associate every field with a hint */
        const hintManagerSection = HINT_SECTION && this.renderHintManager();
        return (
            <>
                {fieldSpecificFields}
                {hintManagerSection}
            </>
        );
    };

    renderUIDividerFields = () => {
        const { currentField } = this.state;
        return <Form.Group widths='equal'>
            <Form.Field>
                <label>Title</label>
                <TextInput onChange={e => this.updateField(e, 'divider.title')} value={currentField.divider?.title}/>
            </Form.Field>
            <Form.Field>
                <label>Icon (SemanticUI tag)</label>
                <TextInput onChange={e => this.updateField(e, 'divider.icon')} value={currentField.divider?.icon}/>
            </Form.Field>
        </Form.Group>
    };

    renderUISegmentFields = () => {
        let segment = {};
        if (this.state.currentField && this.state.currentField.segment) {
            segment = this.state.currentField.segment;
        }

        const { icon, header, text, youtubeID, link, buttonText } = segment;

        return <Form.Group widths='equal'>
            <Form.Field>
                <label>Segment</label>
                <Input label='Ikon' labelPosition='right' placeholder='info' onChange={e => this.updateField(e, 'segment.icon')} value={icon} />
                <Input label='Overskrift' labelPosition='right' onChange={e => this.updateField(e, 'segment.header')} value={header} />
                <textarea placeholder='Brødtekst...' onChange={e => this.updateField(e, 'segment.text')} value={text}/>
                <Input label='YouTube ID' labelPosition='right' placeholder='O6Xo21L0ybE' onChange={e => this.updateField(e, 'segment.youtubeID')} value={youtubeID} />
                <Input label='Andet link' labelPosition='right' placeholder='https://dr.nu/assets/annakournikova.pdf' onChange={e => this.updateField(e, 'segment.link')} value={link} />
                <Input label='Knaptekst' labelPosition='right' placeholder='Download PDF' onChange={e => this.updateField(e, 'segment.buttonText')} value={buttonText} />
            </Form.Field>
        </Form.Group>;
    };

    renderUIGroupOpenFields = () => {
        const { currentField } = this.state;
        return <Form.Group widths='equal'>
            <Form.Field>
                <label>Open by default?</label>
                <Checkbox
                    onChange={e => this.updateField(e, 'options.openByDefault')}
                    checked={_.get(currentField, 'options.openByDefault', false)}
                    toggle
                />
            </Form.Field>
            <Form.Field>
                <label>Always closed?</label>
                <Checkbox
                    onChange={e => this.updateField(e, 'options.alwaysClosed')}
                    checked={_.get(currentField, 'options.alwaysClosed', false)}
                    toggle
                />
            </Form.Field>
        </Form.Group>;
    };

    renderUIFreebieGroupOpenFields = () => {
        const { currentField } = this.state;
        const freebieGroup = currentField?.freebieGroup;
        return <>
            <Form.Group widths='equal'>
                <Form.Field>
                    <label>Title</label>
                    <TextInput onChange={e => this.updateField(e, 'freebieGroup.title')} value={freebieGroup?.title}/>
                </Form.Field>
                <Form.Field>
                    <label>Icon (SemanticUI tag)</label>
                    <TextInput onChange={e => this.updateField(e, 'freebieGroup.icon')} value={freebieGroup?.icon}/>
                </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Field>
                    <label>Call to action button text</label>
                    <TextInput onChange={e => this.updateField(e, 'freebieGroup.btnText')} value={freebieGroup?.btnText}/>
                </Form.Field>
                <Form.Field>
                    <label>Call to action handler ID</label>
                    <select value={freebieGroup?.handlerId} onChange={e => this.updateField(e, 'freebieGroup.handlerId')}>
                        { ['', 'NEXT_SECTION', 'REDIRECT_TO_PAYMENT'].map(id => <option key={id} value={id}>{id}</option>) }
                    </select>
                </Form.Field>
            </Form.Group>
            <Form.Field>
                <label>Hide contents?</label>
                <Checkbox
                    toggle
                    onChange={e => this.updateField(e, 'freebieGroup.hideContents')}
                    checked={freebieGroup?.hideContents}
                />
            </Form.Field>
        </>
    };

    renderUIAutomatedBookingField = () => {
        const { currentField } = this.state;
        return <>
            <Form.Group widths='equal'>
                <Form.Field>
                    <label>Booking target</label>
                    <select value={currentField.automatedBooking?.target} onChange={e => this.updateField(e, 'automatedBooking.target')}>
                        { ['', 'CORPORATE_TAX', 'DEFERRED_TAX', 'DIVIDEND'].map(id => <option key={id} value={id}>{id}</option>) }
                    </select>
                </Form.Field>
            </Form.Group>
        </>
    };

    renderTextSpecificFields = () => {
        const { currentField } = this.state;

        return (
            <Form.Field>
                <label>Tekstværdi</label>
                <TextInput
                    onChange={e => this.updateField(e, 'text')}
                    value={currentField?.text}
                />
            </Form.Field>
        );
    };

    renderEmbeddedVideoSpecificFields = () => {
        const { currentField } = this.state;

        return (
            <>
                <Form.Field>
                    <label>YouTube ID</label>
                    <TextInput
                        placeholder='O6Xo21L0ybE'
                        value={this.state.currentField.youtubeID}
                        onChange={e => this.updateField(e, 'youtubeID')}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Låst i freebie-version?</label>
                    <Checkbox
                        onChange={e => this.updateField(e, 'options.lockedInFreebieVersion')}
                        checked={_.get(currentField, 'options.lockedInFreebieVersion', false)}
                        toggle
                    />
                </Form.Field>
            </>
            
        );
    };

    renderNodeFields = () => {
        const nodeMeta = this.getCurrentFieldModeMetadata();
        const nodeType = nodeMeta?.type;

        // --------------------------------------------------

        // Decide whether to render a standard field or a specific one

        const specialNodeTypes = ['DocumentGenerator'];
        const shouldRenderStandardFields = !specialNodeTypes.includes(nodeType);

        // --------------------------------------------------

        let out = null;

        if(shouldRenderStandardFields) {
            out = this.renderStandardFields();
        } else {
            switch(nodeType) {
                case 'DocumentGenerator': {
                    out = this.renderDocGenOptions();
                    break;
                }
                default: {
                    console.error(`Could not render fields of node type '${nodeType}'`);
                }
            }
        }

        return out;
    };

    renderStandardFields = () => {
        const { currentField } = this.state;

        // --------------------------------------------------

        const isStandardAnswerField = (
            <Form.Field>
                <label>Er standardsvar?</label>
                <Checkbox
                    onChange={e => this.updateField(e, 'options.isStandardAnswer')}
                    checked={_.get(currentField, 'options.isStandardAnswer', false)}
                    toggle
                />
            </Form.Field>
        );

        // --------------------------------------------------

        return (
            <Form.Group widths='equal'>
                {isStandardAnswerField}
            </Form.Group>            
        );
    };

    renderDocGenOptions = () => {
        const { currentField } = this.state;
        const onChange = (_, { value }) => this.updateField({target: {value}}, 'options.docgenVariant');
        const val = _.get(currentField, 'options.docgenVariant');

        return <Form.Group inline>
            <label>Visning:</label>
            <Form.Field
                control={Radio}
                label='PDF'
                value='pdf'
                checked={val === 'pdf'}
                onChange={onChange}
            />
            <Form.Field
                control={Radio}
                label='iXBRL'
                value='iXBRL'
                checked={val === 'iXBRL'}
                onChange={onChange}
            />
            <Form.Field
                control={Radio}
                label='Download-link'
                value='downloadLink'
                checked={val === 'downloadLink'}
                onChange={onChange}
            />
            <Form.Field
                control={Radio}
                label='Underskrifts-flow'
                value='signflow'
                checked={val === 'signflow'}
                onChange={onChange}
            />
        </Form.Group>;
    }

    /**
        Renders hint manager.
    */
    renderHintManager = () => {
        const { currentField } = this.state;

        // --------------------------------------------------

        const dataManager = new DataManager(() => currentField, (propertyPath, value) => {
            /* Conform to the updateField signature */
            const fakeEvent = {
                target: {
                    value
                }
            };
            this.updateField(fakeEvent, propertyPath);
        });

        const hintManager = <HintManager hintType={currentField.hint?.type} dataManager={dataManager} />;
        return hintManager;
    };

    renderConnectedNodeSection = () => {
        const { disableNodeJump } = this.props;
        const { currentField: {nodeId} } = this.state;
        const fieldIds = this.getFieldIds();
        const options = fieldIds
        .sort((a,b) => a.name.localeCompare(b.name))
        .map(node => {
            const { color } = NodeMetadata(node.type);
            return {
                key: node.id,
                value: node.id,
                text: node.name,
                description: node.type,
                style: { '--data-color': color }, // https://css-tricks.com/css-attr-function-got-nothin-custom-properties/
            };
        });

        return (
            <>
                <Form.Field>
                    <label>Tilknyttet knude</label>
                    <Dropdown
                        className='tilknyttetnode'
                        value={nodeId}
                        fluid
                        search
                        selection
                        options={options}
                        onChange={(_, b) =>
                            this.updateField(
                                { target: { value: b.value } },
                                'nodeId'
                            )
                        }
                    />
                </Form.Field>
                {!disableNodeJump && nodeId && (
                    <Form.Field>
                        <ColoredText
                            onClick={() => this.gotoNode(nodeId)}
                            icon='arrow right'
                            iconPosition='left'
                            content='Hop til knude'
                            link
                            underlined={false}
                        />
                    </Form.Field>
                )}
            </>
        );
    };

    renderUITypeSection = () => {
        const { currentField } = this.state;
        const { type } = currentField;
        return (
            <Form.Field>
                <label>UI Element</label>
                <select value={type} onChange={e => this.updateField(e, 'type')}>
                    { uiFieldTypes.map(({ value, text }) => <option key={value} value={value}>{text}</option>) }
                </select>
            </Form.Field>
        );
    };

    render () {
        return (
            <div className='field-manager' style={{ minHeight: this.props.minHeight }}>
                <Grid columns={2}>
                    <Grid.Row style={{ margin: '1em' }}>
                        {this.renderFieldList()}
                        {this.renderSelectedFieldInfo()}
                    </Grid.Row>
                </Grid>
                {this.renderFieldCreationMenu()}
            </div>
        );
    }
}

FieldManager.defaultProps = {
    printable: false,
    disableNodeJump: false,
};

export default withRouter(FieldManager);
