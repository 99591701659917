import React, { useState, useEffect } from 'react';
import { Segment, Header } from 'semantic-ui-react';
import { getCompanyRelations } from 'http/cvr';


const RenderOwnerShipTree = ({ user }) => {

    const [ownerShip, setOwnerShip] = useState([]);
    const daughterCompanies = ownerShip.daughterCompanies || [];
    const legalOwners = ownerShip.owners || [];
    const realOwners = ownerShip.realOwner || [];
      
    useEffect(() => {

      const fetchRelations = async () => {
        const relations = await getCompanyRelations(user.cvr);
        setOwnerShip(relations);
      };

        fetchRelations();
    }, [user]);
   
  
    let ownersText = `Virksomheden har ikke reelle ejere, og ledelsen er indsat som reelle ejere.`;
  
    const renderOwnersList = (owners, title) => (
      <>
        <Segment>
          <div style={{display:'flex', justifyContent: 'space-between', alignItems:'center', margin:'0'}}>
            <Header size='medium'>{title}</Header>
          </div>
          {owners.length < 1 ? (
            <Segment fluid >
            <Header size='medium'>{ownersText}</Header>
          </Segment>
          ) : (
            owners.map((owner, index) => (
              <Segment fluid key={index}>
                <Header>{owner.name}</Header>
                <Header.Subheader>
                  <span className='date'>Ejer {owner.ownershipFraction * 100}% af <b style={{ color: 'black' }}>{user.companyName}</b></span>
                  <br></br>
                </Header.Subheader>
              </Segment>
            ))
          )}
        </Segment>
      </>
    );
  
    const renderCompanyList = (companies, title) => (
      <>
        <Segment>
          <div style={{display:'flex', justifyContent: 'space-between', alignItems:'center'}}>
          <Header style={{margin:'0.5rem 0'}} size='medium' textAlign='left'>{title}</Header>
          </div>
          {companies.map((company, index) => {
            return (
              <Segment
                key={index}
              >
                <Header
                  size='medium'
                >
                  {company.name}
                  <Header.Subheader
                  >
                    Cvr: <span style={{ fontWeight: 'bolder' }}>{company.CVR || null}</span>
                  </Header.Subheader>
                </Header>
              </Segment>
            );
          })}
        </Segment>
      </>
    );
  

    
  
    return (
      <Segment basic>
        {renderOwnersList(realOwners, 'Reelle Ejere')}
        {renderCompanyList(legalOwners, 'Legale Ejere')}
        {renderCompanyList(daughterCompanies, 'Datter Selskaber')}
      </Segment>
    );
  
  };
  export default RenderOwnerShipTree;
