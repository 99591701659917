export const uiSettings = {
    NO_UI: 0,
    CAN_HAVE_UI: 1,
    ALWAYS_UI: 2,
};

export const dataTypes = {
    NUMBER: 'Number',
    NON_PRIMITIVE: 'NonPrimitive',
    BOOLEAN: 'Boolean',
    DATE: 'Date',
    PERIOD: 'Period',
    STRING: 'String',
    STRING_LIST: 'StringList',
    ENUM_STRING: 'EnumString',
    RESOURCE: 'Resource',
};

export const dataTypeDescriptions = {
    [dataTypes.NUMBER]: 'Number',
    [dataTypes.NON_PRIMITIVE]: 'Kompleks',
    [dataTypes.BOOLEAN]: 'Yes/no',
    [dataTypes.DATE]: 'Date',
    [dataTypes.STRING]: 'Text',
    [dataTypes.STRING_LIST]: 'Text list',
    [dataTypes.ENUM_STRING]: 'Enum',
    [dataTypes.RESOURCE]: 'Ressource',
};

export function dataTypeIsPrimitive (dt) {
    return [
        dataTypes.NUMBER,
        dataTypes.BOOLEAN,
        dataTypes.DATE,
        dataTypes.STRING,
        dataTypes.ENUM_STRING,
    ].includes(dt);
}

export const nodeCategories = {
    CALCULATION: {
        id: 'CALCULATION',
        name: 'Beregning',
        icon: 'calculator',
    },
    QUESTIONS: {
        id: 'QUESTIONS',
        name: 'Spørgsmål',
        icon: 'question circle outline',
    },
    RULES: {
        id: 'RULES',
        name: 'Regler',
        icon: 'tasks',
    },
    INTEGRATIONS: {
        id: 'INTEGRATIONS',
        name: 'Integrationer',
        icon: 'cloud',
    },
    MODELS: {
        id: 'MODELS',
        name: 'Årsrulning',
        icon: 'boxes',
    },
    RESOURCES: {
        id: 'RESOURCES',
        name: 'Ressource',
        icon: 'cog',
    },
    OTHER: {
        id: 'OTHER',
        name: 'Andre',
        icon: 'clone outline',
    },
};

const DEFAULT = {
    type: '',
    color: '#e2dede',
    maxDependencies: Infinity,
    allowedDependencyNodeTypes: [],
    allowedDependencyDataTypes: [], // put null to disallow left-hand nodes
    hasUi: uiSettings.NO_UI,
    dataType: dataTypes.NUMBER,
    category: nodeCategories.OTHER.id,
    immutable: false,
    staticId: null,
    deprecated: false,
};

const nodeType = (props = DEFAULT) => {
    const out = Object.assign({}, DEFAULT);
    for (let [key, value] of Object.entries(props)) {
        if (value !== undefined) {
            out[key] = value;
        }
    }
    return out;
};

export const nodeTypes = {
    Product: 'Product',
    SumInputs: 'SumInputs',
    XBRLSum: 'XBRLSum',
    AccountantCodeSum: 'AccountantCodeSum',
    ERPData: 'ERPData',
    ErpSupportsBooking: 'ErpSupportsBooking',
    ErpHasUnbookedPostEntries: 'ErpHasUnbookedPostEntries',
    UnbookedJournals: 'UnbookedJournals',
    ProductDataImporter: 'ProductDataImporter',
    ProductDataElement: 'ProductDataElement',
    RuleSwitch: 'RuleSwitch',
    NumberQuestion: 'NumberQuestion',
    YesNoQuestion: 'YesNoQuestion',
    DateQuestion: 'DateQuestion',
    RadioQuestion: 'RadioQuestion',
    StringQuestion: 'StringQuestion',
    ReportingPeriodQuestion: 'ReportingPeriodQuestion',
    Question: 'Question',
    DocumentGenerator: 'DocumentGenerator',
    CvrOverview: 'CvrOverview',
    CvrDataElement: 'CvrDataElement',
    StaticValue: 'StaticValue',
    Calculation: 'Calculation',
    RawErpViewer: 'RawErpViewer',
    HasRole: 'HasRole',
    Status: 'Status',
    YearReportApproval: 'YearReportApproval',
    YearReportApprovalStatus: 'YearReportApprovalStatus',
    YearReportData: 'YearReportData',
    FlowContext: 'FlowContext',
    SupplementaryXBRLData: 'SupplementaryXBRLData',
    XBRLReport: 'XBRLReport',
    PrimoValue: 'PrimoValue',
    Checklist: 'Checklist',
    HasValidPrimoModel: 'HasValidPrimoModel',
    VSO: 'VSO',
    VSOValue: 'VSOValue',
    XBRLActive: 'XBRLActive',
    AccountantCodeActive: 'AccountantCodeActive',
    MultipleResources: 'MultipleResources',
    MultipleResourcesSum: 'MultipleResourcesSum',
    DateNow: 'DateNow',
    TextToNumber: 'TextToNumber',
    IsFirstYear: 'IsFirstYear',
    ReportingPeriodStartDate: 'ReportingPeriodStartDate',
    ReportingPeriodEndDate: 'ReportingPeriodEndDate',
    IsQuestionFilledOut: 'IsQuestionFilledOut',
    DataSourceTableLookup: 'DataSourceTableLookup',
    UsersERPSystem: 'UsersERPSystem',
};

export const nodeMetadata = {
    [nodeTypes.Product]: nodeType({
        type: nodeTypes.Product,
        color: '#cd99e8',
        hasUi: uiSettings.NO_UI,
        dataType: dataTypes.NON_PRIMITIVE,
        immutable: true,
        category: DEFAULT.category,
    }),
    [nodeTypes.SumInputs]: nodeType({
        type: nodeTypes.SumInputs,
        color: '#c7e293',
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.NUMBER,
        category: nodeCategories.CALCULATION.id,
        allowedDependencyDataTypes: [dataTypes.NUMBER],
    }),
    [nodeTypes.XBRLSum]: nodeType({
        type: nodeTypes.XBRLSum,
        color: '#ff8791',
        hasUi: uiSettings.NO_UI,
        dataType: dataTypes.NUMBER,
        category: nodeCategories.CALCULATION.id,
        allowedDependencyNodeTypes: [nodeTypes.ERPData],
    }),
    [nodeTypes.AccountantCodeSum]: nodeType({
        type: nodeTypes.AccountantCodeSum,
        color: '#f787ff',
        hasUi: uiSettings.NO_UI,
        dataType: dataTypes.NUMBER,
        category: nodeCategories.CALCULATION.id,
        allowedDependencyNodeTypes: [nodeTypes.ERPData],
    }),
    [nodeTypes.UsersERPSystem]: nodeType({
        type: nodeTypes.UsersERPSystem,
        color: '#6dc9c4',
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.STRING,
        category: nodeCategories.INTEGRATIONS.id,
        allowedDependencyNodeTypes: [nodeTypes.ERPData],
    }),
    [nodeTypes.ERPData]: nodeType({
        type: nodeTypes.ERPData,
        color: '#6dc9c4',
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.NON_PRIMITIVE,
        category: nodeCategories.INTEGRATIONS.id,
    }),
    [nodeTypes.ErpSupportsBooking]: nodeType({
        type: nodeTypes.ErpSupportsBooking,
        color: '#7a88ff',
        hasUi: uiSettings.NO_UI,
        dataType: dataTypes.BOOLEAN,
        category: nodeCategories.INTEGRATIONS.id,
    }),
    [nodeTypes.ErpHasUnbookedPostEntries]: nodeType({
        type: nodeTypes.ErpHasUnbookedPostEntries,
        color: '#7a88ff',
        hasUi: uiSettings.NO_UI,
        dataType: dataTypes.BOOLEAN,
        category: nodeCategories.INTEGRATIONS.id,
        allowedDependencyNodeTypes: [nodeTypes.ERPData],
    }),
    [nodeTypes.UnbookedJournals]: nodeType({
        type: nodeTypes.UnbookedJournals,
        color: '#7a88ff',
        hasUi: uiSettings.NO_UI,
        dataType: dataTypes.NUMBER,
        category: nodeCategories.INTEGRATIONS.id,
        allowedDependencyNodeTypes: [nodeTypes.ERPData],
    }),

    [nodeTypes.ProductDataImporter]: nodeType({
        type: nodeTypes.ProductDataImporter,
        color: '#ff7ace',
        hasUi: uiSettings.NO_UI,
        dataType: dataTypes.NON_PRIMITIVE,
        category: nodeCategories.MODELS.id,
        deprecated: true,
    }),
    [nodeTypes.ProductDataElement]: nodeType({
        type: nodeTypes.ProductDataElement,
        color: '#ffa3dd',
        maxDependencies: 1,
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.NUMBER, // But can vary
        category: nodeCategories.MODELS.id,
        allowedDependencyNodeTypes: [nodeTypes.ProductDataImporter],
        deprecated: true,
    }),
    [nodeTypes.RuleSwitch]: nodeType({
        type: nodeTypes.RuleSwitch,
        color: '#9bffbb',
        hasUi: uiSettings.NO_UI,
        dataType: dataTypes.BOOLEAN,
        category: nodeCategories.RULES.id,
    }),
    [nodeTypes.NumberQuestion]: nodeType({
        type: nodeTypes.Question,
        color: '#8a8a8a',
        hasUi: uiSettings.ALWAYS_UI,
        dataType: dataTypes.NUMBER,
        category: nodeCategories.QUESTIONS.id,
    }),
    [nodeTypes.YesNoQuestion]: nodeType({
        type: nodeTypes.Question,
        color: '#8a8a8a',
        hasUi: uiSettings.ALWAYS_UI,
        dataType: dataTypes.BOOLEAN,
        category: nodeCategories.QUESTIONS.id,
    }),
    [nodeTypes.DateQuestion]: nodeType({
        type: nodeTypes.Question,
        color: '#8a8a8a',
        hasUi: uiSettings.ALWAYS_UI,
        dataType: dataTypes.DATE,
        category: nodeCategories.QUESTIONS.id,
    }),
    [nodeTypes.RadioQuestion]: nodeType({
        type: nodeTypes.Question,
        color: '#8a8a8a',
        hasUi: uiSettings.ALWAYS_UI,
        dataType: dataTypes.ENUM_STRING,
        category: nodeCategories.QUESTIONS.id,
    }),
    [nodeTypes.StringQuestion]: nodeType({
        type: nodeTypes.Question,
        color: '#8a8a8a',
        hasUi: uiSettings.ALWAYS_UI,
        dataType: dataTypes.STRING,
        category: nodeCategories.QUESTIONS.id,
    }),
    [nodeTypes.ReportingPeriodQuestion]: nodeType({
        type: nodeTypes.Question,
        color: '#8a8a8a',
        hasUi: uiSettings.ALWAYS_UI,
        dataType: dataTypes.PERIOD,
        category: nodeCategories.QUESTIONS.id,
    }),
    [nodeTypes.DocumentGenerator]: nodeType({
        type: nodeTypes.DocumentGenerator,
        color: '#a78be2',
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.NON_PRIMITIVE,
        category: nodeCategories.INTEGRATIONS.id,
    }),
    [nodeTypes.CvrOverview]: nodeType({
        type: nodeTypes.CvrOverview,
        color: '#e2e270',
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.NON_PRIMITIVE,
        category: nodeCategories.INTEGRATIONS.id,
    }),
    [nodeTypes.CvrDataElement]: nodeType({
        type: nodeTypes.CvrDataElement,
        color: '#c1e270',
        maxDependencies: 1,
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.STRING, // But can vary
        category: nodeCategories.INTEGRATIONS.id,
        allowedDependencyNodeTypes: [nodeTypes.CvrOverview],
    }),
    [nodeTypes.StaticValue]: nodeType({
        type: nodeTypes.StaticValue,
        color: '#c0cde0',
        maxDependencies: 1,
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.STRING, // But can vary
        category: nodeCategories.OTHER.id, 
    }),
    [nodeTypes.Calculation]: nodeType({
        type: nodeTypes.Calculation,
        color: '#34f213',
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.NUMBER,
        category: nodeCategories.CALCULATION.id,
        allowedDependencyDataTypes: [
            dataTypes.NUMBER,
            dataTypes.BOOLEAN,
            dataTypes.DATE,
            dataTypes.STRING,
            dataTypes.ENUM_STRING,
        ],
    }),
    [nodeTypes.RawErpViewer]: nodeType({
        type: nodeTypes.RawErpViewer,
        color: '#d3d3d3',
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.STRING,
        category: nodeCategories.INTEGRATIONS.id, 
    }),
    [nodeTypes.HasRole]: nodeType({
        type: nodeTypes.HasRole,
        color: '#6accb0',
        hasUi: uiSettings.NO_UI,
        dataType: dataTypes.BOOLEAN,
        category: nodeCategories.RULES.id, 
    }),
    [nodeTypes.Status]: nodeType({
        type: nodeTypes.Status,
        color: '#00a9ff',
        hasUi: uiSettings.NO_UI,
        dataType: dataTypes.STRING,
        staticId: 'STATUS',
        category: nodeCategories.OTHER.id, 
    }),
    [nodeTypes.YearReportApproval]: nodeType({
        type: nodeTypes.YearReportApproval,
        color: '#fff7bd',
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.NON_PRIMITIVE,
        staticId: 'IS_APPROVABLE',
        category: nodeCategories.OTHER.id,
        deprecated: true,
    }),
    [nodeTypes.YearReportApprovalStatus]: nodeType({
        type: nodeTypes.YearReportApprovalStatus,
        color: '#fffcbd',
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.STRING,
        staticId: 'APPROVAL_STATUS',
        category: nodeCategories.OTHER.id, 
        deprecated: true,
    }),
    [nodeTypes.YearReportData]: nodeType({
        type: nodeTypes.YearReportData,
        color: '#c0a4fc',
        hasUi: uiSettings.ALWAYS_UI,
        dataType: dataTypes.NON_PRIMITIVE,
        category: nodeCategories.OTHER.id, 
    }),
    [nodeTypes.FlowContext]: nodeType({
        type: nodeTypes.FlowContext,
        color: '#159e3e',
        hasUi: uiSettings.ALWAYS_UI,
        dataType: dataTypes.NON_PRIMITIVE,
        category: nodeCategories.OTHER.id, 
    }),
    [nodeTypes.SupplementaryXBRLData]: nodeType({
        type: nodeTypes.SupplementaryXBRLData,
        color: '#6ae6a1',
        hasUi: uiSettings.NO_UI,
        dataType: dataTypes.NON_PRIMITIVE,
        category: nodeCategories.INTEGRATIONS.id, 
    }),
    [nodeTypes.XBRLReport]: nodeType({
        type: nodeTypes.XBRLReport,
        color: '#6ae6a1',
        hasUi: uiSettings.ALWAYS_UI,
        dataType: dataTypes.NON_PRIMITIVE,
        category: nodeCategories.INTEGRATIONS.id,
        allowedDependencyNodeTypes: [
            nodeTypes.YearReportData,
            nodeTypes.DocumentGenerator,
            nodeTypes.FlowContext,
            nodeTypes.SupplementaryXBRLData,
        ],
    }),
    [nodeTypes.PrimoValue]: nodeType({
        type: nodeTypes.PrimoValue,
        color: '#faedb9',
        hasUi: uiSettings.ALWAYS_UI,
        dataType: dataTypes.NUMBER,
        category: nodeCategories.MODELS.id, 
    }),
    [nodeTypes.Checklist]: nodeType({
        type: nodeTypes.Checklist,
        color: '#226f7d',
        hasUi: uiSettings.ALWAYS_UI,
        dataType: dataTypes.NON_PRIMITIVE,
        category: nodeCategories.RULES.id,
        maxDependencies: 1,
        allowedDependencyNodeTypes: [nodeTypes.YearReportData],
    }),
    [nodeTypes.HasValidPrimoModel]: nodeType({
        type: nodeTypes.HasValidPrimoModel,
        color: '#3eb55d',
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.BOOLEAN,
        category: nodeCategories.MODELS.id,
    }),
    [nodeTypes.VSO]: nodeType({
        type: nodeTypes.VSO,
        color: '#e2a870',
        hasUi: uiSettings.NO_UI,
        dataType: dataTypes.NUMBER,
        category: nodeCategories.INTEGRATIONS.id,
    }),
    [nodeTypes.VSOValue]: nodeType({
        type: nodeTypes.VSOValue,
        color: '#f0b378',
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.NUMBER,
        category: nodeCategories.INTEGRATIONS.id,
        allowedDependencyNodeTypes: [nodeTypes.VSO],
    }),
    [nodeTypes.XBRLActive]: nodeType({
        type: nodeTypes.XBRLActive,
        color: '#ff87c3',
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.BOOLEAN,
        category: nodeCategories.RULES.id,
        allowedDependencyNodeTypes: [nodeTypes.ERPData],
    }),
    [nodeTypes.AccountantCodeActive]: nodeType({
        type: nodeTypes.AccountantCodeActive,
        color: '#fc8ba3',
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.BOOLEAN,
        category: nodeCategories.RULES.id,
        allowedDependencyNodeTypes: [nodeTypes.ERPData],
    }),
    [nodeTypes.MultipleResources]: nodeType({
        type: nodeTypes.MultipleResources,
        color: '#781bb3',
        hasUi: uiSettings.ALWAYS_UI,
        dataType: dataTypes.NON_PRIMITIVE,
        category: nodeCategories.RESOURCES.id,
    }),
    [nodeTypes.MultipleResourcesSum]: nodeType({
        type: nodeTypes.MultipleResourcesSum,
        color: '#872ebf',
        hasUi: uiSettings.ALWAYS_UI,
        dataType: dataTypes.NUMBER,
        category: nodeCategories.RESOURCES.id,
        allowedDependencyNodeTypes: [nodeTypes.MultipleResources],
    }),
    [nodeTypes.DateNow]: nodeType({
        type: nodeTypes.DateNow,
        color: '#2ebdbf',
        hasUi: uiSettings.NO_UI,
        dataType: dataTypes.DATE,
        category: nodeCategories.OTHER.id,
    }),
    [nodeTypes.TextToNumber]: nodeType({
        type: nodeTypes.TextToNumber,
        color: '#2ebf91',
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.NUMBER,
        category: nodeCategories.OTHER.id,
        allowedDependencyDataTypes: [dataTypes.STRING],
    }),
    [nodeTypes.IsFirstYear]: nodeType({
        type: nodeTypes.IsFirstYear,
        color: '#4bbf2e',
        hasUi: uiSettings.NO_UI,
        dataType: dataTypes.BOOLEAN,
        category: nodeCategories.OTHER.id,
    }),
    [nodeTypes.ReportingPeriodStartDate]: nodeType({
        type: nodeTypes.ReportingPeriodStartDate,
        color: '#70e2c0',
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.DATE,
        category: nodeCategories.INTEGRATIONS.id,
    }),
    [nodeTypes.ReportingPeriodEndDate]: nodeType({
        type: nodeTypes.ReportingPeriodEndDate,
        color: '#70e2c0',
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.DATE,
        category: nodeCategories.INTEGRATIONS.id,
    }),
    [nodeTypes.IsQuestionFilledOut]: nodeType({
        type: nodeTypes.IsQuestionFilledOut,
        color: '#20c74c',
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.BOOLEAN,
        category: nodeCategories.RULES.id,
        allowedDependencyDataTypes: [
            dataTypes.STRING,
            dataTypes.ENUM_STRING,
            dataTypes.NUMBER,
            dataTypes.BOOLEAN,
            dataTypes.DATE,
        ],
    }),
    [nodeTypes.DataSourceTableLookup]: nodeType({
        type: nodeTypes.DataSourceTableLookup,
        color: '#d18660', // TODO
        hasUi: uiSettings.CAN_HAVE_UI,
        dataType: dataTypes.STRING, // but can vary
        category: nodeCategories.OTHER.id,
    }),
};

const getNodeMetadata = nodeType => nodeMetadata[nodeType] || DEFAULT;

export default getNodeMetadata;