import React, { useState } from 'react';
import ReactFileReader from 'react-file-reader';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Container, Form, Icon, Input, List, Loader, Menu, Popup, Segment, Table, Transition } from 'semantic-ui-react';
import lodash from 'lodash';
import fileDownload from 'js-file-download';
import productEngine from 'http/productEngine';
import modelEditor from 'http/modelEditor';
import reportEditor from 'http/reportEditor';
import { withUser } from 'shared/LoginManager';
import { alert, confirm, prompt } from 'shared/globalModal';
import { getFileName, readAsText } from 'shared/files';
import { withStore } from '../../../store';

import styles from './ModelMaster.module.css';

const SortRadio = ({ label, checked, direction, onClick }) => {
    let icon;
    let color;
    let textColor;

    if (checked) {
        color = 'green';
        textColor = 'black';
        icon = direction === 1 ? 'caret square up' : 'caret square down';
    } else {
        color = 'grey';
        textColor = 'grey';
        icon = 'square outline';
    }

    return (
        <span onClick={onClick} className={styles.noselect}>
            <Icon name={icon} color={color} />
            <span style={{ color: textColor }}>{label}</span>
        </span>
    );
};

const CreateForm = ({ onSubmit, error, properties, onPropertyChange }) => {
    const formFields = properties.map(({ label, placeholder, name }, i) => {
        return (
            <Form.Field>
                <label>{label}</label>
                <Input
                    placeholder={placeholder}
                    onChange={(_, { value }) => onPropertyChange(name, value)}
                    autoFocus={i === 0}
                />
            </Form.Field>
        );
    });

    return (
        <Form onSubmit={onSubmit} style={{ borderSpacing: '0px' }}>
            {formFields}
            <Form.Field>
                <Button content='Opret' icon='plus' fluid />
                <Transition visible={error} animation='fade' duration={500}>
                    <div
                        style={{
                            color: 'red',
                            textAlign: 'center',
                            width: '100%',
                            marginTop: '4px',
                        }}
                        children={error}
                    />
                </Transition>
            </Form.Field>
        </Form>
    );
};

const ActionIcon = ({ name, tooltip, color, onClick }) => {
    const iconTrigger = (
        <Icon
            name={name}
            color={color}
            onClick={onClick}
            link
        />
    );

    return (
        <Popup
            position='top center'
            content={tooltip}
            trigger={iconTrigger}
            inverted
        />
    );
};

/**
 * 
 * @param {Object} props
 * @param {{name: string, link: string, onCopy?: function, onDownload?: function, onDelete?: function}[]} props.items
 * @returns 
 */
const CRUDTable = ({ headerContent, items }) => {
    // prepare header
    let tableHeader;

    if (headerContent) {
        tableHeader = (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell colSpan={2} verticalAlign='middle'>
                        {headerContent}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    const tableRows = items.map(({ name, link, onCopy, onDownload, onReplace, onDelete }) => (
        <Table.Row>
            <Table.Cell>
                <b>
                    <Link to={link}>{name}</Link>
                </b>
            </Table.Cell>
            <Table.Cell textAlign='right'>
                {
                    onCopy &&
                        <ActionIcon
                        tooltip='Opret en kopi'
                        name='copy outline'
                        color='black'
                        onClick={onCopy}
                    />
                }
                {
                    onDownload &&
                    <ActionIcon
                        tooltip='Download'
                        name='download'
                        color='black'
                        onClick={onDownload}
                    />
                }
                {
                    onReplace &&
                    <ActionIcon
                        tooltip='Erstat'
                        name='exchange'
                        color='black'
                        onClick={onReplace}
                    />
                }
                {
                    onDelete &&
                    <ActionIcon
                        tooltip='Slet'
                        name='x'
                        color='red'
                        onClick={onDelete}
                    />
                }                
            </Table.Cell>
        </Table.Row>
    ));

    return (
        <Table size='large' striped>
            {tableHeader}
            <Table.Body>
                {tableRows}
            </Table.Body>
        </Table>
    );
};

const FoldableSegment = ({ headerText, headerIcon, content }) => {
    const [open, setOpen] = useState(false);

    if (!content) return null;

    return (
        <Segment style={{ padding: '0.5em' }} secondary={!open} stacked={!open}>
            <div onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
                <strong><Icon name={headerIcon} /> {headerText}</strong>
            </div>
            {open && content}
        </Segment>
    );
};

const ModelDiffExplorer = ({ nodeDiff, oldModelID, newModelID }) => {
    const { addedNodeIds, deletedNodeIds, changedNodeIds, oldNodes, newNodes } = nodeDiff;

    const renderNodesTable = (nodes, showChanges = false) => {
        if (nodes.length === 0) return null;

        const cellEllipsisStyle = {
            maxWidth: '300px',
            overflow: 'hidden', 
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        };

        return (
            <Table basic='very' compact='very'>
                <Table.Header>
                    <Table.HeaderCell>Navn</Table.HeaderCell>
                    <Table.HeaderCell>Tag</Table.HeaderCell>
                    {showChanges && (
                        <Table.HeaderCell textAlign='center'>Nodedata ændret</Table.HeaderCell>
                    )}
                </Table.Header>
                <Table.Body>
                    {[...nodes].map(({ name, tag, changed }) => {
                        return (
                            <Table.Row>
                                <Table.Cell style={cellEllipsisStyle} title={name}>{name}</Table.Cell>
                                <Table.Cell style={cellEllipsisStyle} title={tag}>{tag}</Table.Cell>
                                {showChanges && (
                                    <Table.Cell textAlign='center'>
                                        <Icon
                                            name={changed ? 'check' : 'x'}
                                            color={changed ? 'green' : 'black'}
                                        />
                                        {changed ? 'Ja' : 'Nej'}
                                    </Table.Cell>
                                )}
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        );
    };

    const renderedChangedNodes = changedNodeIds.map(id => {
        const oldNode = oldNodes[id];
        const newNode = newNodes[id];

        const renderText = (oldText, newText) => {
            if (oldText === newText) {
                return newText || <i>Ingen</i>;
            }

            return (
                <span title={`${oldText} -> ${newText}`}>
                    <span
                        style={{
                            color: 'red',
                            textDecoration: 'line-through',
                            opacity: 0.75
                        }}
                    >
                        {oldText || <i>Ingen</i>}
                    </span>
                    &nbsp;<Icon name='caret right' />
                    <span style={{ color: 'green' }}>{newText || <i>Ingen</i>}</span>
                </span>
            );
        };
        
        return {
            name: renderText(oldNode.name, newNode.name),
            tag: renderText(oldNode.tag, newNode.tag),
            changed: oldNode.dataHash !== newNode.dataHash,
        };
    });

    const nodeIdDiscrepancyWarning = oldModelID !== newModelID && (
        <span>
            <Icon name='warning sign' color='red' />
            OBS: Model ID mismatch mellem valgte model og uploadede model:&nbsp;
            <strong>{oldModelID}</strong> vs.&nbsp;
            <strong>{newModelID}</strong>
        </span>
    );

    return (
        <div>
            {nodeIdDiscrepancyWarning}
            <FoldableSegment
                headerText={`Noder tilføjet: ${addedNodeIds.length}`}
                headerIcon='plus'
                content={renderNodesTable(addedNodeIds.map(n => newNodes[n]))}
            />
            <FoldableSegment
                headerText={`Noder fjernet: ${deletedNodeIds.length}`}
                headerIcon='trash'
                content={renderNodesTable(deletedNodeIds.map(n => oldNodes[n]))}
            />
            <FoldableSegment
                headerText={`Noder ændret: ${changedNodeIds.length}`}
                headerIcon='pencil'
                content={renderNodesTable(renderedChangedNodes, true)}
            />
        </div>
    );
};

class ModelMaster extends React.Component {
    constructor (props) {
        super(props);

        // init tabs
        this.tabs = [
            {
                name: 'Modeller',
                icon: 'share alternate',
                render: this.renderModelsTab,
                onlyAdmin: false,
            },
            {
                name: 'Produkter',
                icon: 'boxes',
                render: this.renderProductsTab,
                onlyAdmin: true,
            },
            {
                name: 'Ressourceskabeloner',
                icon: 'cog',
                render: this.renderResourceTemplatesTab,
                onlyAdmin: true,
            },
            {
                name: 'Rapportskabeloner',
                icon: 'file',
                render: this.renderReportTemplatesTab,
                onlyAdmin: false,
            },
            {
                name: 'Data source tabeller',
                icon: 'table',
                render: this.renderDataSourceTablesTab,
                onlyAdmin: true,
            },
        ];

        this.replaceModelFileRef = React.createRef();
        this.replaceReportTemplateFileRef = React.createRef();

        // init model sort options
        this.modelSortOptions = [
            { label: 'Ændret', property: 'updatedAt' },
            { label: 'Oprettet', property: 'createdAt' },
        ];

        // init state
        this.state = {
            activeTab: this.getTabFromHash() || this.tabs[0],
            modelSortBy: this.modelSortOptions[0],
            products: [],
            models: [],
            resourceTemplates: [],
            reportTemplates: [],
            dataSourceTables: [],
            loading: true,
            modelSortDirection: -1,
            modelFilter: '',
            onlyShowProductionModels: false,
            idOfModelToReplace: '',
            idOfReportTemplateToReplace: '',
            ...this.getCleanFormState(),
        };
    }

    userIsAdmin = () => {
        return this.props.user.user.roles.includes('admin');
    };

    getTabFromHash = () => {
        if (!window.location.hash) {
            return null;
        }
        const hashValue = window.location.hash.substr(1);
        return this.tabs.find(tab => tab.name === hashValue);
    };

    getCleanFormState = () => ({
        createName: '',
        createID: '',
        createError: '',
    });

    componentDidMount = () => {
        this.readRequiredData();
    };

    readRequiredData = async () => {
        try {
            const jobs = [
                this.readAllModels(),
                this.readAllReportTemplates(),
            ];

            if (this.userIsAdmin()) {
                jobs.push(
                    this.readAllResourceTemplates(),
                    this.readAllProducts(),
                    this.readAllDataSourceTables(),
                );
            }

            // try load models && products
            await Promise.all(jobs);

            const { updateMenu } = this.props.store;
            const menuItems = [];
            updateMenu(menuItems);
        } catch {
            this.setState({
                generalError: 'Produkter og modeller kunne ikke hentes...',
            });
        }

        this.setState({ loading: false });
    }

    readAllResourceTemplates = async () => {
        const resourceTemplates = await productEngine.getResourceTemplates();

        this.setState({
            resourceTemplates: resourceTemplates.filter(template => {
                return !template.isSystemDefined;
            }),
        });
    };

    readAllReportTemplates = async () => {
        const reportTemplates = await reportEditor.getTemplates();

        reportTemplates.sort((templateA, templateB) => {
            const createdAtUnix = template => new Date(template.createdAt).getTime();
            
            return createdAtUnix(templateA) - createdAtUnix(templateB);
        });

        this.setState({ reportTemplates });
    };

    readAllDataSourceTables = async () => {
        const dataSourceTables = await productEngine.getDataSourceTables();

        this.setState({ dataSourceTables });
    };

    readAllProducts = async () => {
        const products = await modelEditor.getProducts();

        this.setState({
            products: products.reverse(),
        });
    };

    readAllModels = async () => {
        const models = await modelEditor.getModels();

        this.setState({
            models: models.reverse(),
        });
    };

    suggestAdvancement = name => {
        return name.replace(/([0-9]{4})/gi, (_, match) => {
            return Number(match) + 1;
        });
    };

    createModel = async () => {
        const modelId = this.state.createID;
        if (!modelId) {
            this.setState({
                createError: 'Indtast et id til modellen!',
            });
            return;
        }
        
        const modelName = this.state.createName;
        if (!modelName) {
            this.setState({
                createError: 'Indtast et modelnavn!',
            });
            return;
        }

        try {
            const createdModelID = await modelEditor.createModel(modelId, modelName);
            this.props.history.push(`/model-editor/${createdModelID}/nodes`);
        } catch {
            this.setState({
                createError: 'Modellen kunne ikke oprettes',
            });
        }
    };

    createProduct = async () => {
        const productId = this.state.createID;
        if (!productId) {
            this.setState({ createError: 'Indtast et id til produktet!' });
            return;
        }
        
        const productName = this.state.createName;
        if (!productName) {
            this.setState({ createError: 'Indtast et produktnavn!' });
            return;
        }

        try {
            await modelEditor.createProduct(productId, productName);
            this.props.history.push(`/model-editor/products/${productId}`);
        } catch {
            this.setState({
                createError: 'Fejl under oprettelsen af produktet',
            });
            return;
        }
    };

    createResourceTemplate = async () => {
        const name = this.state.createName;
        const slug = this.state.createID;

        if (!name || !slug) {
            this.setState({ createError: 'Navn og ID er påkrævet' });
            return;
        }

        await productEngine.createResourceTemplate({ name, slug });

        this.props.history.push(`/model-editor/resource-templates/${slug}`);
    };

    createReportTemplate = async () => {
        const name = this.state.createName;
        if (!name) {
            this.setState({ createError: 'Navn er påkrævet' });
            return;
        }

        const newTemplate = await reportEditor.createTemplate(name);

        this.props.history.push(`/model-editor/report-templates/${newTemplate.id}`);
    };

    createDataSourceTable = async () => {
        const slug = this.state.createName;
        if (!slug) {
            this.setState({ createError: 'Navn/ID er påkrævet' });
            return;
        }

        if (this.state.dataSourceTables.some(table => table.slug === slug)) {
            this.setState({ createError: `Tabellen "${slug}" eksisterer allerede!` });
            return;
        }

        await productEngine.upsertDataSourceTable({ slug });

        this.props.history.push(`/model-editor/data-source-tables/${slug}`);
    };

    deleteProduct = async (productId, productName) => {
        const userConfirmation = await prompt('Indtast produktets navn for at slette den');
        if (!userConfirmation) {
            return;
        }

        if (userConfirmation !== productName) {
            alert('Din indtastning matcher ikke produktets navn');
            return;
        }

        try {
            await modelEditor.deleteProduct(productId, productName);
        } catch {
            alert('Kunne ikke slette produktet...');
            return;
        }

        await this.readAllProducts();
    }

    deleteModel = async (id, name) => {
        const userConfirmation = await prompt('Indtast modellens navn for at slette den');
        if (!userConfirmation) {
            return;
        }

        if (userConfirmation !== name) {
            alert('Din indtastning matcher ikke modellens navn');
            return;
        }

        try {
            await modelEditor.deleteModel(id, name);
        } catch {
            alert('Kunne ikke slette modellen...');

        }

        await this.readAllModels();
    }

    cloneModel = async (oldID, oldName) => {
        const newId = await prompt({
            header: 'Indtast et nyt model id',
            value: this.suggestAdvancement(oldID),
        });

        if (!newId) {
            return;
        }

        const newName = await prompt({
            header: 'Indtast et nyt navn',
            value: this.suggestAdvancement(oldName),
        });

        if (!newName) {
            return;
        }

        try {
            await modelEditor.cloneModel(oldID, newId, newName);
        } catch {
            alert('Kunne ikke klone modellen...');
            return
        }

        await this.readAllModels();
    }

    exportModel = async id => {
        const model = await modelEditor.getModel(id);
        const filename = `${model.metadata.name}.json`;
        const modelJson = JSON.stringify(model, null, 2);
        fileDownload(modelJson, filename);
    };

    openReplaceModelFilePrompt = id => {
        this.setState({ idOfModelToReplace: id }, () => {
            this.replaceModelFileRef.current.click();
        });
    };

    replaceModel = async modelFile => {
        const { idOfModelToReplace } = this.state;

        if (!idOfModelToReplace) {
            return;
        }

        
        const fileContents = await readAsText(modelFile);
        
        const model = JSON.parse(fileContents);
        
        const modelDiff = await modelEditor.getModelDiff(idOfModelToReplace, model);

        const didConfirm = await confirm({
            header: 'Overskriv model',
            confirmButton: 'Bekræft overskrivning',
            content: <ModelDiffExplorer
                {...modelDiff}
                oldModelID={model.metadata.id}
                newModelID={idOfModelToReplace}
            />,
        });

        if (!didConfirm) {
            return;
        }

        await modelEditor.replaceModel(idOfModelToReplace, model);

        toast.success('Modellen blev overskrevet');
    };

    importModel = async files => {
        const file = files.fileList[0];

        const defaultName = getFileName(file);

        // gather name
        const newName = await prompt({
            header: 'Indtast et navn',
            value: defaultName,
        });

        if (!newName) {
            return;
        }

        // get ID
        const newId = await prompt({
            header: 'Indtast et ID',
            value: defaultName,
        });

        if (!newId) {
            return;
        }

        try {
            const modelJSON = await readAsText(file);
            await modelEditor.importModel(newId, newName, modelJSON);
            this.props.history.push(`/model-editor/${newId}/nodes`);
        } catch {
            this.setState({
                createError: 'Fejl under import af modellen'
            });
        }
    };

    filterAndSortCollection = (collectionToSort) => {
        let { modelFilter, modelSortBy, modelSortDirection } = this.state;

        // do filtering by name
        let collection = [...collectionToSort];
        if (modelFilter) {
            modelFilter = modelFilter.toLowerCase();
            collection = collection.filter(element => {
                return element.name.toLowerCase().includes(modelFilter);
            });
        }

        // do sorting
        collection = lodash.sortBy(collection, [o => o[modelSortBy.property]]);
        if (modelSortDirection === -1) {
            collection = collection.reverse();
        }

        return collection;
    };

    defaultCreateFormProperties = () => ([
        {
            label: 'Navn',
            name: 'createName',
            placeholder: 'Indtast navn',
        },
        {
            label: 'ID',
            name: 'createID',
            placeholder: 'Indtast ID',
        },
    ]);

    renderCreateForm = (title, onSubmit, properties = this.defaultCreateFormProperties()) => {
        const createButton = (
            <Button
                content={title}
                icon='plus'
                color='green'
                floated='right'
                labelPosition='right'
            />
        );

        const form = (
            <CreateForm
                onSubmit={onSubmit}
                error={this.state.createError}
                onPropertyChange={(name, value) => this.setState({ [name]: value })}
                properties={properties}
            />
        );

        return (
            <Popup
                onClose={() => this.setState({ ...this.getCleanFormState() })}
                trigger={createButton}
                content={form}
                position='left center'
                on='click'
            />
        );
    };

    renderFilterAndSortOptions = () => {
        const { modelSortBy, modelSortDirection } = this.state;

        return (
            <>
                <Input
                    autoFocus
                    clearable 
                    icon='search'
                    placeholder='Filtrér'
                    iconPosition='left'
                    defaultValue={this.state.modelFilter}
                    onChange={(_, { value }) => this.setState({ modelFilter: value })}
                />
                <List horizontal style={{ marginLeft: '10px' }}>
                    {this.modelSortOptions.map(opt => (
                        <List.Item>
                            <SortRadio
                                label={opt.label}
                                checked={modelSortBy.property === opt.property}
                                direction={modelSortDirection}
                                onClick={() => {
                                    if (opt.property === modelSortBy.property) {
                                        // only flip direction
                                        this.setState({
                                            modelSortDirection: modelSortDirection === 1 ? -1 : 1,
                                        });
                                        return;
                                    }

                                    // set new sort prop && reset direction
                                    this.setState({
                                        modelSortBy: opt,
                                        modelSortDirection: -1,
                                    })
                                }}
                            />
                        </List.Item>
                    ))}
                </List>
            </>
        );
    };

    renderModelsTab = () => {
        const { onlyShowProductionModels } = this.state;
        
        // do model filtering by name
        const models = this.filterAndSortCollection(this.state.models);

        const createModelForm = this.renderCreateForm('Opret model', this.createModel);

        const tableHeader = (
            <>
                {createModelForm}
                <ReactFileReader
                    handleFiles={this.importModel}
                    fileTypes={['.json']}
                    multipleFiles={false}
                    base64={true}
                    children={
                        <Button
                            icon='upload'
                            content='Importer model'
                            floated='right'
                            labelPosition='right'
                        />
                    }
                />
                {this.renderFilterAndSortOptions()}
                <Checkbox
                    style={{ marginLeft: '1em' }}
                    checked={onlyShowProductionModels}
                    onChange={(_, { checked }) => this.setState({ onlyShowProductionModels: checked })}
                    label='Vis produktionsmodeller'
                    slider
                />
            </>
        );

        const modelsToShow = models.filter(model => {
            if (onlyShowProductionModels) {
                return model.includedInProduct;
            }

            return true;
        });
        
        return (
            <>
                {this.renderHiddenUploadField({
                    ref: this.replaceModelFileRef,
                    onSelectFile: file => this.replaceModel(file),
                })}
                <CRUDTable
                    headerContent={tableHeader}
                    items={modelsToShow.map(({ name, id }) => ({
                        name,
                        link: `/model-editor/${id}/nodes`,
                        onCopy: () => this.cloneModel(id, name),
                        onDownload: () => this.exportModel(id),
                        onReplace: () => this.openReplaceModelFilePrompt(id),
                        onDelete: () => this.deleteModel(id, name),
                    }))}
                />
            </>
        );
    };

    renderProductsTab = () => {
        const { products } = this.state;
        const createProductForm = this.renderCreateForm('Opret produkt', this.createProduct);

        return (
            <CRUDTable
                headerContent={createProductForm}
                items={products.map(({ productName, productId }) => ({
                    name: productName,
                    link: `/model-editor/products/${productId}`,
                    onDelete: () => this.deleteProduct(productId, productName),
                }))}
            />
        );
    };

    renderResourceTemplatesTab = () => {
        const { resourceTemplates } = this.state;
        const createResource = this.renderCreateForm('Opret ressourceskabelon', this.createResourceTemplate);

        return (
            <CRUDTable
                headerContent={createResource}
                items={resourceTemplates.map(({ name, slug, semanticIcon }) => ({
                    name: (
                        <span>
                            <Icon name={semanticIcon || 'cog'} />
                            {name}
                        </span>
                    ),
                    link: `/model-editor/resource-templates/${slug}`,
                }))}
            />
        );
    };

    renderReportTemplatesTab = () => {
        const createReportTemplate = this.renderCreateForm('Opret rapportskabelon', this.createReportTemplate, [
            {
                label: 'Navn',
                name: 'createName',
                placeholder: 'Navn...',
            },
        ]);

        const filteredAndSortedTemplates = this.filterAndSortCollection(this.state.reportTemplates);

        return (
            <>
                {this.renderHiddenUploadField({
                    ref: this.replaceReportTemplateFileRef,
                    onSelectFile: async file => {
                        const { idOfReportTemplateToReplace } = this.state;
                        if (!idOfReportTemplateToReplace) {
                            return;
                        }

                        const fileContents = await readAsText(file);

                        const reportTemplate = JSON.parse(fileContents);

                        const templateIDMismatch = idOfReportTemplateToReplace !== reportTemplate._id;

                        const confirmed = await confirm({
                            header: 'Overskriv rapportskabelon',
                            confirmButton: 'Overskriv rapportskabelon',
                            content: (
                                <>
                                    {templateIDMismatch && (
                                        <div>
                                            <Icon name='warning sign' color='red' /> <b>OBS:</b>&nbsp;
                                            Der er mismatch mellem ID på valgte rapportskabelon og uploadede rapportskabelon.
                                        </div>
                                    )}
                                    <div>
                                        Er du sikker på at du vil overskrive rapportskabelonen?
                                    </div>
                                </>
                            ),
                        });

                        if (!confirmed) {
                            return;
                        }

                        await reportEditor.updateTemplate(idOfReportTemplateToReplace, reportTemplate.data);

                        toast.success('Rapportskabelonen blev overskrevet');
                    },
                })}
                {<CRUDTable
                    headerContent={
                        <>
                            {this.renderFilterAndSortOptions()}
                            {createReportTemplate}
                        </>
                    }
                    items={filteredAndSortedTemplates.map(({ name, id }) => ({
                        name,
                        link: `/model-editor/report-templates/${id}`,
                        onDownload: async () => {
                            const template = await reportEditor.getTemplate(id);
                            const filename = `${template.data.metadata.name}.json`;
                            const asJSON = JSON.stringify(template, null, '  ');
                            fileDownload(asJSON, filename);
                        },
                        onCopy: async () => {
                            await reportEditor.copyTemplate(id);
                            await this.readAllReportTemplates();
                        },
                        onDelete: async () => {
                            const isSure = await confirm(`Are you sure you want to delete "${name}"?`);
                            if (!isSure) {
                                return;
                            }
                            await reportEditor.deleteTemplate(id);
                            await this.readAllReportTemplates();
                        },
                        onReplace: () => {
                            this.setState({ idOfReportTemplateToReplace: id });
                            this.replaceReportTemplateFileRef.current.click();
                        },
                    }))}
                />}
            </>
        );
    };

    renderDataSourceTablesTab = () => {
        const createReportTemplate = this.renderCreateForm('Opret data source tabel', this.createDataSourceTable, [
            {
                label: 'Navn/ID',
                name: 'createName',
                placeholder: 'Navn/ID...',
            },
        ]);

        return (
            <CRUDTable
                headerContent={createReportTemplate}
                items={this.state.dataSourceTables.map(({ slug }) => {
                    return {
                        name: slug,
                        link: `/model-editor/data-source-tables/${slug}`,
                    };
                })}
            />
        );
    };

    renderActiveTab = () => {
        const { activeTab, loading } = this.state;

        return (
            <div>
                <Loader inline='centered' size='huge' active={loading} />
                {!loading && (
                    <div>
                        {activeTab.render()}
                    </div>
                )}
            </div>
        );
    };

    renderTabsMenu = () => {
        return (
            <Menu>
                {this.tabs.map(tab => {
                    if (tab.onlyAdmin && !this.userIsAdmin()) {
                        return null;
                    }

                    const { name, icon } = tab;
                    return (
                        <Menu.Item
                            content={name}
                            icon={icon}
                            active={this.state.activeTab.name === name}
                            onClick={() => this.setState({ activeTab: tab })}
                        />
                    );
                })}
            </Menu>
        );
    };

    renderHiddenUploadField = ({ ref, onSelectFile }) => {
        return (
            <input
                ref={ref}
                type='file'
                accept='.json'
                style={{ display: 'none' }}
                onChange={e => {
                    const [firstFile] = e.target.files || [];
                    firstFile && onSelectFile(firstFile);
                    e.target.value = '';
                }}
            />
        );
    };

    render = () => {
        return (
            <Container>
                {this.renderTabsMenu()}
                <div style={{ padding: '4px', marginTop: '20px' }}>
                    {this.renderActiveTab()}
                </div>
            </Container>
        );
    };
}

export default withUser(withStore(ModelMaster));
