import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Checkbox, Dropdown, Form, Grid, Icon, Input, Menu, Message, Search } from 'semantic-ui-react';
import lodash from 'lodash';
import Tooltip from 'atoms/Tooltip';

import { useReportTemplatesConfig } from '../config';
import ImageAssetFormField from '../ImageAssetFormField';
import BlockView from './BlockView';
import LanguageFormFields from '../LanguageFormFields';
import * as blockService from '../blockService';
import model from '../model';
import TaxonomyTagInput from '../TaxonomyTagInput';
import CodeTextarea from '../CodeTextarea';
import WidthSelector from '../WidthSelector';
import buildSuggestionsFromDefinitions from '../buildSuggestionsFromDefinitions';

const FormLabel = ({ name, description }) => {
    const tooltip = description?.trim();
    return (
        <label>{name} {tooltip && <Tooltip content={tooltip} />}</label>
    );
};

const BlockForm = ({ block, onChange, supportedLanguages, category, suggestions, calculationFunctions, textVariations, inputDefinitions, taxonomy, metadata, pdfTemplate }) => {
    const blockKind = model.blockTypes[block.kind];
    const schema = blockKind.schema;
    const formFields = schema[category] || [];

    const mutateBlock = mutator => {
        const blockCopy = { ...block };
        mutator(blockCopy);
        onChange(blockCopy);
    };

    const getDefinitionsByDataType = desiredDataType => {
        return inputDefinitions
            .filter(({ dataType }) => dataType === desiredDataType)
            .map(({ tag }) => ({
                key: tag,
                value: tag,
                text: tag,
            }));
    };

    const renderFormField = ({ name, slug, placeholder, dataType, choices, description, dependsOn, tagDataType, nullable, isIXBRLFeature }) => {
        const ixbrlFeaturesAreActive = metadata.outputAsIXBRL;
        if (!ixbrlFeaturesAreActive && isIXBRLFeature) {
            return null;
        }

        const formFieldLabel = (
            <FormLabel
                name={name}
                description={description}
            />
        );
        switch (dataType) {
            case model.blockPropertyDataTypes.string: {
                return (
                    <LanguageFormFields
                        onChange={(lang, value) => mutateBlock(block => {
                            lodash.set(block, ['data', slug, lang].join('.'), value);
                        })}
                        label={formFieldLabel}
                        languages={supportedLanguages}
                        values={lodash.get(block?.data, slug)}
                        suggestions={suggestions}
                        textVariations={textVariations}
                        placeholder={placeholder}
                    />
                );
            }

            case model.blockPropertyDataTypes.number: {
                return (
                    <>
                        {formFieldLabel}
                        <Input
                            type='number'
                            placeholder={placeholder || `${name}...`}
                            defaultValue={block.data[slug]}
                            onChange={(_, { value }) => mutateBlock(block => {
                                /[0-9]+/g.test(value)
                                    ? block.data[slug] = Number(value)
                                    : block.data[slug] = null;
                            })}
                        />
                    </>
                );
            }

            case model.blockPropertyDataTypes.boolean: {
                return (
                    <>
                        {formFieldLabel}
                        <Checkbox
                            defaultChecked={block.data[slug]}
                            onChange={(_, { checked }) => mutateBlock(block => block.data[slug] = checked)}
                            toggle
                        />
                    </>
                );
            }

            case model.blockPropertyDataTypes.enum: {
                return (
                    <>
                        {formFieldLabel}
                        <Dropdown
                            selection
                            search
                            clearable={nullable}
                            defaultValue={block.data[slug]}
                            onChange={(_, { value }) => mutateBlock(block => block.data[slug] = value)}
                            placeholder={`${name}...`}
                            options={
                                Object.values(choices).map(({ id, label }) => ({
                                    key: id,
                                    text: label,
                                    value: id,
                                }))
                            }
                        />
                    </>
                );
            }

            case model.blockPropertyDataTypes.code: {
                return (
                    <>
                        {formFieldLabel}
                        <CodeTextarea
                            defaultValue={block.data[slug]}
                            onChange={value => mutateBlock(block => block.data[slug] = value)}
                            suggestions={suggestions}
                            calculationFunctions={calculationFunctions}
                        />
                    </>
                );
            }

            case model.blockPropertyDataTypes.taxonomyTag: {
                const tagInTaxonomy = block.data[slug] in taxonomy;
                return (
                    <>
                        {formFieldLabel}
                        <TaxonomyTagInput
                            defaultValue={block.data[slug]}
                            onChange={(_, { value }) => mutateBlock(block => block.data[slug] = value)}
                            placeholder={`${name}...`}
                            icon={tagInTaxonomy && <Icon name='check circle' color='green' />}
                        />
                    </>
                );
            }

            case model.blockPropertyDataTypes.tagList: {
                return (
                    <>
                        {formFieldLabel}
                        <Dropdown
                            selection
                            search
                            fluid
                            multiple
                            defaultValue={block.data[slug]}
                            onChange={(_, { value }) => mutateBlock(block => block.data[slug] = value)}
                            placeholder={`${name}...`}
                            options={getDefinitionsByDataType(model.definitionDataTypes.Number)}
                        />
                    </>
                );
            }

            case model.blockPropertyDataTypes.tag: {
                return (
                    <>
                        {formFieldLabel}
                        <Dropdown
                            selection
                            search
                            clearable
                            defaultValue={block.data[slug]}
                            onChange={(_, { value }) => mutateBlock(block => block.data[slug] = value)}
                            placeholder={`${name}...`}
                            options={getDefinitionsByDataType(tagDataType)}
                        />
                    </>
                );
            }

            case model.blockPropertyDataTypes.widths: {
                return (
                    <>
                        {formFieldLabel}
                        <WidthSelector
                            columns={block.data[dependsOn]}
                            value={block.data[slug]}
                            onChange={value => mutateBlock(block => block.data[slug] = value)}
                        />
                    </>
                );
            }

            case model.blockPropertyDataTypes.image: {
                return (
                    <ImageAssetFormField
                        label={name}
                        defaultValue={block.data[slug]}
                        onChange={image => mutateBlock(block => block.data[slug] = image)}
                    />
                );
            }

            case model.blockPropertyDataTypes.color: {
                return (
                    <>
                        <FormLabel name={name} description='Any legal CSS color value will work' />
                        <Input
                            placeholder={placeholder || `${name}...`}
                            defaultValue={block.data[slug]}
                            onChange={(_, { value }) => mutateBlock(block => block.data[slug] = value)}
                        />
                    </>
                );
            }

            case model.blockPropertyDataTypes.blockIDRef: {
                const options = (
                    pdfTemplate
                    .blocks
                    .filter(block => !!block.internalLabel)
                    .map(block => ({
                        text: block.internalLabel,
                        value: block.id,
                    }))
                );

                return (
                    <>
                        <FormLabel
                            name={name}
                            description='You can only link to blocks w/ an internal block label'
                        />
                        <Dropdown
                            selection
                            search
                            clearable
                            placeholder='Choose a block that this block will link to...'
                            defaultValue={block.data[slug]}
                            onChange={(_, { value }) => mutateBlock(block => block.data[slug] = value)}
                            options={options}
                        />
                    </>
                );
            }

            default: {
                return null;
            }
        }
    };

    const formFieldsJSX = [];

    if (category === model.schemaCategories.meta.id && !blockService.isCloser(block)) {
        formFieldsJSX.push(
            <Form.Field>
                <label>
                    Internal block label{' '}
                    <Tooltip
                        content='Will overwrite the default label on the block, but only in the editor'
                    />
                </label>
                <Input
                    placeholder='Leave empty to use default label...'
                    onChange={(_, { value }) => mutateBlock(block => block.internalLabel = value)}
                    defaultValue={block.internalLabel}
                />
            </Form.Field>
        );
    }

    const doRenderFormField = formField => {
        if (Array.isArray(formField)) {
            const renderedFields = formField.map(doRenderFormField);

            if (renderedFields.every(field => !field)) {
                return null;
            }

            return (
                <Form.Group widths='equal'>
                    {formField.map(doRenderFormField)}
                </Form.Group>
            );
        }

        const renderedField = renderFormField(formField);
        if (!renderedField) {
            return null;
        }

        return (
            <Form.Field key={formField.slug}>
                {renderedField}
            </Form.Field>
        );
    };

    formFields.forEach(formField => {
        formFieldsJSX.push(doRenderFormField(formField));
    });

    const filteredFormFields = formFieldsJSX.filter(field => !!field);

    return (
        <Form style={{ width: '100%', borderSpacing: '0px' }} key={block.id}>
            {
                filteredFormFields.length > 0
                    ? filteredFormFields
                    : (
                        <Form.Field>
                            <Message>
                                <Icon name='info circle' />
                                No {category} configuration available for {blockKind.label.toLowerCase()} blocks
                            </Message>
                        </Form.Field>
                    )
            }
        </Form>
    );
};

const tabs = Object.keys(model.schemaCategories).map(category => {
    const { name, semanticIcon } = model.schemaCategories[category];
    return {
        category,
        name,
        icon: semanticIcon,
    };
});

const BlockEditor = ({ metadata, inputDefinitions, pdfTemplate, setPdfTemplate, suggestions, calculationFunctions, textVariations, taxonomy }) => {
    const [selectedBlockID, setSelectedBlockID] = useReportTemplatesConfig('selectedField', null);
    const [activeTabIdx, setActiveTabIdx] = useReportTemplatesConfig('selectedBlockTab', 0);
    const [chosenLanguage] = useReportTemplatesConfig('chosenLanguage');
    const [scrollToBlock, setScrollToBlock] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const searchRef = useRef();
    const supportedLanguages = metadata?.supportedLanguages || [];

    useLayoutEffect(() => {
        if (scrollToBlock) {
            setScrollToBlock(false);
        }
    }, [scrollToBlock]);

    const searchResult = useMemo(() => {
        const query = searchQuery?.trim()?.toLowerCase();
        if (!query) {
            return [];
        }

        const result = pdfTemplate.blocks.filter(block => {
            return JSON.stringify(block).toLowerCase().includes(query);
        });

        return result.slice(0, 5);
    }, [searchQuery, pdfTemplate.blocks]);


    const updateSelectedblock = (updator = {}) => {
        setPdfTemplate({
            ...pdfTemplate,
            blocks: pdfTemplate.blocks.map(block => {
                if (block.id !== selectedBlockID) {
                    return block;
                }

                return {
                    ...block,
                    ...updator,
                };
            })
        });
    };

    const updateBlocks = blocks => {    
        setPdfTemplate({
            ...pdfTemplate,
            blocks: [...blocks],
        });
    };

    const getResourceDefinitionOverridesForBlock = useCallback(block => {
        const blockStacktrace = blockService.getBlockStackTrace(pdfTemplate.blocks, block.id).reverse();
        const firstResourceBlockAncestor = blockStacktrace.find(block => {
            return block.kind === model.blockTypes.resource.id;
        });

        // exit early if current selected block doesn't have a resource block ancestor
        if (!firstResourceBlockAncestor) {
            return {};
        }

        // allows nested resources
        const resourceAncestorOfFirstResourceBlock = getResourceDefinitionOverridesForBlock(firstResourceBlockAncestor);

        let inputDefinitionsToUse;
        if (resourceAncestorOfFirstResourceBlock?.inputDefinitions) {
            inputDefinitionsToUse = resourceAncestorOfFirstResourceBlock.inputDefinitions;
        } else {
            inputDefinitionsToUse = inputDefinitions; // use "global" definitons
        }

        const selectedResourceDefinition = inputDefinitionsToUse.find(definition => {
            return definition.tag === firstResourceBlockAncestor.data?.resource;
        });

        if (!selectedResourceDefinition) {
            return {};
        }

        const { subDefinitions } = selectedResourceDefinition;

        return {
            inputDefinitions: subDefinitions,
            suggestions: buildSuggestionsFromDefinitions(subDefinitions),
        };
    }, [inputDefinitions, pdfTemplate.blocks]);

    const renderSelectedBlockEditor = () => {
        const selectedBlock = pdfTemplate?.blocks.find(block => {
            return block.id === selectedBlockID;
        });

        if (!selectedBlock) {
            return <i>Select a block</i>;
        }

        const activeCategoriesForSelectedBlock = blockService.getBlockTypeActiveSchemaCategories(selectedBlock.kind);

        const definitionsToUse = {
            inputDefinitions,
            suggestions,
            ...getResourceDefinitionOverridesForBlock(selectedBlock),
        };
        
        const { category } = tabs[activeTabIdx];

        return (
            <div style={{ position: 'sticky', top: '4.5em' }}>
                <Menu pointing>
                    {tabs.map((tab, tabIdx) => {
                        const isActive = tabIdx === activeTabIdx;
                        return (
                            <Menu.Item
                                key={tab.name}
                                icon={tab.icon}
                                content={tab.name}
                                active={isActive}
                                onClick={() => setActiveTabIdx(tabIdx)}
                                disabled={!isActive && !activeCategoriesForSelectedBlock.includes(tab.category)}
                            />
                        );
                    })}
                    <Menu.Menu position='right'>
                        <div className='ui right aligned category search item'>
                            <Search
                                ref={searchRef}
                                fluid
                                placeholder='Search blocks...'
                                noResultsMessage='No blocks found...'
                                input={{ transparent: true, clearable: true }}
                                onResultSelect={(_, { result }) => {
                                    const blocksCopy = [...pdfTemplate.blocks];
                                    blockService.expandTargetBlock(blocksCopy, result.blockID);
                                    setScrollToBlock(true);
                                    setSelectedBlockID(result.blockID);
                                    updateBlocks(blocksCopy);
                                    searchRef.current.setValue('');
                                }}
                                onSearchChange={(_, { value }) => setSearchQuery(value.trim())}
                                results={searchResult.map(block => {
                                    const label = blockService.getLabel(block, inputDefinitions, chosenLanguage);
                                    const blockIcon = blockService.getIcon(block);
                                    const blockKind = blockService.getBlockTypeLabel(block);
                                    return {
                                        key: block.id,
                                        blockID: block.id,
                                        title: label,
                                        description: <span><Icon name={blockIcon} /> {blockKind}</span>,
                                    };
                                })}
                            />
                        </div>
                    </Menu.Menu>
                </Menu>
                <BlockForm
                    category={category}
                    block={selectedBlock}
                    pdfTemplate={pdfTemplate}
                    onChange={updateSelectedblock}
                    supportedLanguages={supportedLanguages}
                    calculationFunctions={calculationFunctions}
                    textVariations={textVariations}
                    taxonomy={taxonomy}
                    scrollToBlock={scrollToBlock}
                    metadata={metadata}
                    {...definitionsToUse}
                />
            </div>
        );
    };

    const blockViewJSX = (
        <BlockView
            blocks={pdfTemplate?.blocks}
            setBlocks={updateBlocks}
            selectedBlockID={selectedBlockID}
            setSelectedBlockID={setSelectedBlockID}
            supportedLanguages={supportedLanguages}
            inputDefinitions={inputDefinitions}
            scrollToBlock={scrollToBlock}
        />
    );

    if (pdfTemplate?.blocks?.length > 0) {
        return (
            <Grid>
                <Grid.Column width={8}>
                    {blockViewJSX}
                </Grid.Column>
                <Grid.Column width={8}>
                    {renderSelectedBlockEditor()}
                </Grid.Column>
            </Grid>
        );
    }

    return blockViewJSX;
};


export default BlockEditor;