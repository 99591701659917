import uuid from 'uuid/v4';
import React, { Component } from 'react';
import productEngine from 'http/productEngine';
import { Button, Dropdown, Form, Icon, Loader, Segment, Table } from 'semantic-ui-react';
import getNodeMetadata, { dataTypes, nodeTypes } from 'model-editor/nodeMetadata';

const makeNewRule = () => {
    return {
        id: uuid(),
        tableColumnID: '',
        nodeID: '',
    };
};

class NodeDataDataSourceTableLookup extends Component {
    state = {
        dataSourceTables: [],
        loading: true,
        data: {
            selectedDataSourceTableSlug: '',
            idOfColumnToExtract: '',
            rules: [makeNewRule()],
        },
    };

    componentDidMount = async () => {
        const dataSourceTables = await productEngine.getDataSourceTables();

        this.setState({
            loading: false,
            dataSourceTables,
            data: {
                ...this.state.data,
                ...this.props.node.data,
            },
        });
    };

    updateData = (updator = {}) => {
        const newData = { ...this.state.data, ...updator };
        this.setState({ data: newData });
    };

    updateRuleAtIdx = (ruleIdx, updator = {}) => {
        const updatedRules = this.state.data.rules.map((rule, i) => {
            if (i === ruleIdx) {
                return {
                    ...rule,
                    ...updator,
                };
            }

            return rule;
        });

        this.updateData({ rules: updatedRules });
    };

    deleteRuleAtIdx = ruleIdx => {
        const updatedRules = this.state.data.rules.filter((_, i) => {
            return ruleIdx !== i;
        });

        this.updateData({ rules: updatedRules });
    };

    getData = () => {
        return this.state.data;
    };

    getDatatype = () => {
        const { selectedDataSourceTableSlug, idOfColumnToExtract } = this.state.data;
        const table = this.state.dataSourceTables.find(table => table.slug === selectedDataSourceTableSlug);
        const column = table?.columnsMetadata.find(column => column.id === idOfColumnToExtract);

        if (!column) {
            const defaultDataType = getNodeMetadata(nodeTypes.DataSourceTableLookup).dataType;
            return defaultDataType;
        }
        
        return {
            string: dataTypes.STRING,
            number: dataTypes.NUMBER,
        }[column.dataType];
    };

    render = () => {
        const { loading, dataSourceTables, data } = this.state;

        if (loading) {
            return (
                <Loader
                    active
                    inline='centered'
                />
            );
        }

        const selectedTable = dataSourceTables.find(table => {
            return table.slug === data.selectedDataSourceTableSlug;
        });

        const tableColumnOptions = selectedTable?.columnsMetadata?.map(column => {
            return {
                text: column.id,
                value: column.id, 
            };
        });

        const edgeNodes = this.props.node.edges.map(edge => {
            const corrospondingNode = this.props.nodes.find(node => {
                return node.id === edge;
            });

            return corrospondingNode;
        });

        return (
            <Segment>
                <Form>
                    <Form.Field>
                        <label>Vælg data source table</label>
                        <Dropdown
                            selection
                            search
                            defaultValue={data.selectedDataSourceTableSlug}
                            onChange={(_, { value }) => this.updateData({
                                selectedDataSourceTableSlug: value,
                                idOfColumnToExtract: '',
                                rules: [],
                            })}
                            options={dataSourceTables.map(table => {
                                return {
                                    text: table.slug,
                                    value: table.slug,
                                };
                            })}
                        />
                    </Form.Field>
                    {selectedTable && (
                        <Form.Field>
                            <label>Vælg den kolonneværdi du vil trække ud</label>
                            <Dropdown
                                selection
                                defaultValue={data.idOfColumnToExtract}
                                onChange={(_, { value }) => this.updateData({ idOfColumnToExtract: value })}
                                options={tableColumnOptions}
                            />
                        </Form.Field>
                    )}
                    {data.idOfColumnToExtract && (
                        <Form.Field>
                            <label>Opsæt regler for udtræk</label>
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Data source table kolonne</Table.HeaderCell>
                                        <Table.HeaderCell />
                                        <Table.HeaderCell>Node</Table.HeaderCell>
                                        <Table.HeaderCell />
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {data.rules.map((rule, ruleIdx) => {
                                        const selectedColumn = selectedTable.columnsMetadata.find(column => {
                                            return column.id === rule.tableColumnID;
                                        });

                                        const relevantNodes = edgeNodes.filter(node => {
                                            switch (node.dataType) {
                                                case dataTypes.STRING:
                                                case dataTypes.ENUM_STRING:
                                                    return selectedColumn?.dataType === 'string';
                                                case dataTypes.NUMBER:
                                                    return selectedColumn?.dataType === 'number';
                                                default:
                                                    return false;
                                            }
                                        });

                                        return (
                                            <Table.Row key={rule.id}>
                                                <Table.Cell width={7}>
                                                    <Dropdown
                                                        selection
                                                        search
                                                        fluid
                                                        placeholder='Vælg kolonne...'
                                                        defaultValue={rule.tableColumnID}
                                                        options={tableColumnOptions}
                                                        onChange={(_, { value }) => {
                                                            this.updateRuleAtIdx(ruleIdx, { tableColumnID: value });
                                                        }}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell textAlign='center'>skal være lig med</Table.Cell>
                                                <Table.Cell width={6}>
                                                    <Dropdown
                                                        selection
                                                        search
                                                        fluid
                                                        placeholder='Vælg node...'
                                                        defaultValue={rule.nodeID}
                                                        onChange={(_, { value }) => this.updateRuleAtIdx(ruleIdx, { nodeID: value })}
                                                        options={relevantNodes.map(node => {
                                                            return {
                                                                text: node.name,
                                                                value: node.id,
                                                            };
                                                        })}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Icon
                                                        name='trash'
                                                        link
                                                        onClick={() => this.deleteRuleAtIdx(ruleIdx)}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                                <Table.Footer>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={4} textAlign='right'>
                                            <Button
                                                icon='plus'
                                                content='Ny regel'
                                                onClick={() => this.updateData({
                                                    rules: [...data.rules, makeNewRule()], 
                                                })}
                                            />
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                            </Table>
                        </Form.Field>
                    )}
                </Form>
            </Segment>
        );
    };
}

export default NodeDataDataSourceTableLookup;