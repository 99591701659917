import React                         from 'react';
import { useParams }                 from 'react-router-dom';
import { nodeTypes }                 from 'model-editor/nodeMetadata';
import NodeDataSumInputs             from './NodeDataSumInputs';
import NodeDataXBRLSum               from './NodeDataXBRLSum';
import NodeDataRuleSwitch            from './NodeDataRuleSwitch';
import NodeDataQuestion              from './NodeDataQuestion';
import NodeDataProductDataImporter   from './NodeDataProductDataImporter';
import NodeDataProductDataElement    from './NodeDataProductDataElement';
import NodeDataStaticValue           from './NodeDataStaticValue';
import NodeDataCalculation           from './NodeDataCalculation';
import NodeDataCvrDataElement        from './NodeDataCvrDataElement';
import NodeDataCvrOverview           from './NodeDataCvrOverview';
import NodeDataHasRole               from './NodeDataHasRole';
import NodeDataStatus                from './NodeDataStatus'
import NodeDataYearReportData        from './NodeDataYearReportData';
import NodeDataFlowContext           from './NodeDataFlowContext';
import NodeDataPrimoValue            from './NodeDataPrimoValue';
import NodeDataERPData               from './NodeDataERPData';
import NodeDataErpSupportsBooking    from './NodeDataErpSupportsBooking';
import NodeDataChecklist             from './NodeDataChecklist';
import NodeDataVSOValue              from './NodeDataVSOValue';
import NodeDataTagActive             from './NodeDataTagActive';
import NodeDataMultipleResources     from './NodeDataMultipleResources';
import NodeDataMultipleResourcesSum  from './NodeDataMultipleResourcesSum';
import NodeDataDocumentGenerator     from './NodeDataDocumentGenerator';
import NodeDataDataSourceTableLookup from './NodeDataDataSourceTableLookup';

const getRelevantNodeComponent = nodeType => {
    const componentTable = {
        [nodeTypes.AccountantCodeActive]: NodeDataTagActive,
        [nodeTypes.AccountantCodeSum]: NodeDataXBRLSum,
        [nodeTypes.Calculation]: NodeDataCalculation,
        [nodeTypes.Checklist]: NodeDataChecklist,
        [nodeTypes.CvrDataElement]: NodeDataCvrDataElement,
        [nodeTypes.CvrOverview]: NodeDataCvrOverview,
        [nodeTypes.DocumentGenerator]: NodeDataDocumentGenerator,
        [nodeTypes.ERPData]: NodeDataERPData,
        [nodeTypes.ErpSupportsBooking]: NodeDataErpSupportsBooking,
        [nodeTypes.FlowContext]: NodeDataFlowContext,
        [nodeTypes.HasRole]: NodeDataHasRole,
        [nodeTypes.MultipleResources]: NodeDataMultipleResources,
        [nodeTypes.MultipleResourcesSum]: NodeDataMultipleResourcesSum,
        [nodeTypes.PrimoValue]: NodeDataPrimoValue,
        [nodeTypes.ProductDataElement]: NodeDataProductDataElement,
        [nodeTypes.ProductDataImporter]: NodeDataProductDataImporter,
        [nodeTypes.Question]: NodeDataQuestion,
        [nodeTypes.RuleSwitch]: NodeDataRuleSwitch,
        [nodeTypes.StaticValue]: NodeDataStaticValue,
        [nodeTypes.Status]: NodeDataStatus,
        [nodeTypes.SumInputs]: NodeDataSumInputs,
        [nodeTypes.VSOValue]: NodeDataVSOValue,
        [nodeTypes.XBRLActive]: NodeDataTagActive,
        [nodeTypes.XBRLSum]: NodeDataXBRLSum,
        [nodeTypes.YearReportData]: NodeDataYearReportData,
        [nodeTypes.DataSourceTableLookup]: NodeDataDataSourceTableLookup,
    };
    
    return componentTable[nodeType] || React.Fragment;
};

const NodeData = ({ node, model, allNodes, nodeDataRef, taxonomy }) => {
    const { modelId } = useParams();
    const NodeDataComponent = getRelevantNodeComponent(node.type);

    const nodes = model.nodes;
    const edges = model.edges.map(edge => edge.to);

    return (
        <NodeDataComponent
            node={{
                ...node,
                nodes,
                edges: edges.filter(edge => edge !== node.id),
            }}
            model={model}
            modelId={modelId}
            nodes={allNodes}
            ref={nodeDataRef}
            taxonomy={taxonomy}
        />
    );
};

export default NodeData;