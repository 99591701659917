import { Client } from './client';
import { API_LOCATION } from '../config';

const cvrClient = new Client(`${API_LOCATION}/cvr`);

function getSammenligningstal (cvr, date) {
    return cvrClient.getResource(`/${cvr}/sammenligningstal/${date}`);
}
export function getCompanyRelations(cvr) {
    return cvrClient.getResource(`/${cvr}/relationer`);
}

const cvr = {
    getSammenligningstal,
    getCompanyRelations
};

export default cvr;