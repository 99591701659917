import React, { Component, createRef } from 'react';
import { Button, Grid, Header, Loader, Message, Segment } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import EmailEditor from 'react-email-editor';

import email from 'http/email';
import { withRESTResource } from 'molecules/withRESTResource';
import { readAsText } from 'shared/files';
import FileuploadButton from 'atoms/FileuploadButton';
import AutoStamp from 'atoms/AutoStamp';
import VariableTable from './VariableTable';
import extractPreviewText from './extractPreviewText';

import styles from './TemplatesDetail.module.css';

class TemplatesDetail extends Component {
    editor = createRef();
    name = createRef();
    state = {
        editorReady: false,
        editorLoaded: false,
        iframeHeight: 0,
        working: false,
    };

    doWork = fn => {
        return async () => {
            this.setState({ working: true });
            await fn();
            this.setState({ working: false });
        };
    };

    componentDidUpdate = () => {
        const { editorLoaded, editorReady } = this.state;
        if (!editorLoaded && editorReady && this.editor.current) {
            this.setState({ editorLoaded: true });
            this.editor.current.loadDesign(this.props.rest.data.markdown);
        }
    };

    exportEmail = () => {
        const editor = this.editor.current;
        if (!editor) {
            return Promise.resolve(null);
        }

        return new Promise(resolve => editor.exportHtml(resolve));
    };

    handleHTMLFileSelected = async file => {
        const fileData = await readAsText(file);
        this.props.setLocalRestData({
            html: fileData,
            isExternalHTML: true,
            markdown: undefined,
        });
    };

    save = async () => {
        let { id, name, isExternalHTML, html } = this.props.rest.data;

        let markdown = null;
        if (!isExternalHTML) {
            const exported = await this.exportEmail();
            if (!exported) {
                return;
            }

            html = exported.html;
            markdown = exported.design;
        }

        const previewText = extractPreviewText(html).slice(0, 135);

        try {
            await email.patchTemplate(id, {
                html,
                markdown,
                name,
                isExternalHTML,
                previewText,
            });
            toast.success(`"${name}" blev gemt`);
            this.props.history.push('/emails/templates');
        } catch (e) {
            toast.error(<span>Skabelonen blev ikke gemt:<br />{e.message}</span>);
        }
    };

    onEditorLoaded = () => {
        this.setState({ editorReady: true });
    };

    renderTemplate = () => {
        const { working } = this.state;
        const template = this.props.rest.data;
        return (
            <div>
                <Grid columns={2} verticalAlign='middle'>
                    <Grid.Column>
                        <Header size='large'>
                            <input
                                defaultValue={template.name}
                                className={styles.nameInput}
                                placeholder='Navngiv din skabelon'
                                spellCheck={false}
                                onChange={e => this.props.setLocalRestData({ name: e.target.value })}
                            />
                            <Header.Subheader>
                                Sidst ændret d. <AutoStamp stamp={template.updatedAt} />
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column>
                        <Button
                            disabled={working}
                            primary
                            content='Gem'
                            labelPosition='right'
                            icon='save'
                            onClick={this.doWork(this.save)}
                            floated='right'
                        />
                        {!template.isExternalHTML && (
                            <FileuploadButton
                                content='Upload HTML'
                                icon='upload'
                                color='black'
                                floated='right'
                                accept={['html']}
                                onChange={this.handleHTMLFileSelected}
                            />
                        )}
                        {template.isExternalHTML && (
                            <Button
                                content='Brug Unlayer Editor'
                                icon='arrow left'
                                color='black'
                                floated='right'
                                onClick={() => this.props.setLocalRestData({
                                    isExternalHTML: false,
                                    html: '',
                                })}
                            />
                        )}
                    </Grid.Column>
                </Grid>
                <Segment
                    className={styles.editorContainer}
                    raised
                    loading={working}
                    disabled={working}
                >
                    {!template.isExternalHTML && (
                        <EmailEditor
                            onLoad={this.onEditorLoaded}
                            ref={this.editor}
                            minHeight={window.screen.availHeight * 0.70}
                        />
                    )}
                    {template.isExternalHTML && (
                        <iframe
                            title='Uploaded template view'
                            srcDoc={template.html}
                            style={{
                                width: '100%',
                                height: this.state.iframeHeight + 'px',
                                border: 'none',
                            }}
                            onLoad={e => {
                                const iframeDoc = e.target.contentDocument || e.target.contentWindow.document;
                                iframeDoc.body.style.overflow = 'hidden';
                                iframeDoc.body.style.margin = 0;
                                const iframeHeight = iframeDoc.documentElement.scrollHeight;
                                this.setState({ iframeHeight });
                            }}
                        />
                    )}
                </Segment>
                <div className={styles.variablesContainer}>
                    <VariableTable />
                </div>
            </div>
        );
    };

    render = () => {
        const { loading, error } = this.props.rest;
        if (loading) {
            return <Loader inline='centered' active />
        }

        if (error) {
            return <Message
                error
                content='Der opstod en fejl'
            />;
        }

        return this.renderTemplate();
    };
}

export default withRESTResource(TemplatesDetail, ({ match }) => {
    return email.getTemplate(match.params.template);
});