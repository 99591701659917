import ColoredText from 'atoms/ColoredText';
import { Button, Checkbox, Dropdown, Form, Icon, Input, Segment, Table } from 'semantic-ui-react';
import { swap } from 'shared/array';
import uuid from 'uuid/v4';

const TablesEditor = ({ tables, onChange, fieldIds }) => {
    const update = (tableID, updator = {}) => {
        const updatedTables = tables.map(t => {
            if (t.id !== tableID) {
                return t;
            }

            return {
                ...t,
                ...updator,
            };
        });

        onChange(updatedTables);
    };

    const renderedTableDefinitions = tables.map(table => {
        return (
            <Segment key={table.id}>
                <Form>
                    <Form.Field>
                        <label>Titel</label>
                        <Input
                            defaultValue={table.title}
                            onChange={(_, { value }) => update(table.id, { title: value })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Tillad filupload på tabelrækker?</label>
                        <Checkbox
                            toggle
                            checked={table.allowFileuploadPerRow}
                            onChange={(_, { checked }) => update(table.id, { allowFileuploadPerRow: checked })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Kolonner</label>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Header</Table.HeaderCell>
                                    <Table.HeaderCell>Tag</Table.HeaderCell>
                                    <Table.HeaderCell />
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {table.columns.map((column, idx) => {
                                    const updateColumn = (updator = {}) => {
                                        const newColumns = table.columns.map(c => {
                                            if (c.id !== column.id) return c;
                                            return {
                                                ...c,
                                                ...updator
                                            };
                                        });

                                        update(table.id, { columns: newColumns });
                                    };

                                    return (
                                        <Table.Row key={column.id}>
                                            <Table.Cell width={8}>
                                                <Input
                                                    onChange={(_, { value }) => updateColumn({ header: value })}
                                                    placeholder='Indtast header'
                                                    defaultValue={column.header}
                                                    fluid 
                                                />
                                            </Table.Cell>
                                            <Table.Cell width={7}>
                                                <Dropdown
                                                    fluid
                                                    selection
                                                    search
                                                    defaultValue={column.tag}
                                                    onChange={(_, { value }) => updateColumn({ tag: value })}
                                                    options={fieldIds.map(field => {
                                                        return {
                                                            text: field.name,
                                                            value: field.id,
                                                        };
                                                    })}
                                                />
                                            </Table.Cell>
                                            <Table.Cell width={1} textAlign='right'>
                                                <Icon
                                                    name='arrow down'
                                                    link
                                                    disabled={idx === table.columns.length - 1}
                                                    onClick={() => {
                                                        const newColumns = [...table.columns];
                                                        swap(newColumns, idx, idx + 1);
                                                        update(table.id, { columns: newColumns });
                                                    }}
                                                />
                                                <Icon
                                                    name='arrow up'
                                                    link
                                                    disabled={idx === 0}
                                                    onClick={() => {
                                                        const newColumns = [...table.columns];
                                                        swap(newColumns, idx, idx - 1);
                                                        update(table.id, { columns: newColumns });
                                                    }}
                                                />
                                                <Icon
                                                    link
                                                    name='trash'
                                                    onClick={() => {
                                                        update(table.id, { columns: table.columns.filter(c => c.id !== column.id) });
                                                    }}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={3} textAlign='right'>
                                        <ColoredText
                                            link
                                            underlined={false}
                                            icon='plus'
                                            content='Ny kolonne'
                                            onClick={() => {
                                                const newColumns = [...table.columns, { id: uuid(), header: '', tag: '' }];
                                                update(table.id, { columns: newColumns });
                                            }}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    </Form.Field>
                    <Form.Field>
                        <ColoredText
                            link
                            onClick={() => onChange(tables.filter(t => t.id !== table.id))}
                            underlined={false}
                            iconPosition='left'
                            color='red'
                            content='Slet tabel'
                            icon='trash'
                        />
                    </Form.Field>
                </Form>
            </Segment>
        );
    });

    return (
        <>
            {renderedTableDefinitions}
            <div style={{ textAlign: 'right' }}>
                <Button
                    onClick={() => {
                        onChange([...tables, {
                            id: uuid(),
                            title: '',
                            columns: [],
                        }])
                    }}
                    primary
                    icon='plus'
                    content='Opret tabel'
                />
            </div>
        </>
    );
};

export default TablesEditor;