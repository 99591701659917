import React, { Component, useState } from 'react';
import { toast } from 'react-toastify';
import lodash from 'lodash';
import uuid from 'uuid/v4';
import interpret from '@digital-revisor/node-formula-interpreter/lib';
import findAllReferences from '@digital-revisor/node-formula-interpreter/lib/findAllReferences';
import { Button, Checkbox, Container, Dropdown, Form, Grid, Header, Icon, Input, Label, List, Loader, Message, Modal, Popup, Segment, Table } from 'semantic-ui-react';
import { dataTypeDescriptions, dataTypes } from 'model-editor/nodeMetadata';
import FormulaInput from 'model-editor/molecules/FormulaInput';
import productEngine from 'http/productEngine';
import { createSlug } from 'shared/createSlug';
import { confirm } from 'shared/globalModal';
import { swap } from 'shared/array';
import IconPicker from 'atoms/IconPicker';
import Tooltip from 'atoms/Tooltip';
import RadioQuestionEditor from 'model-editor/atoms/RadioQuestionEditor';

const formStyles = {
    width: '100%',
    borderSpacing: '0px',
};

const cleanProperty = () => ({
    required: true,
});

const PropertySettingsEditorModal = ({ open, onClose, property, updateProperty, resourceTemplate, allResourceTemplates }) => {
    if (!property) {
        return null;
    }
    
    const renderGeneralOptions = () => {
        return (
            <>
                <Form.Field>
                    <label>Tooltip</label>
                    <Input
                        onChange={(_, { value }) => updateProperty({ tooltip: value })}
                        defaultValue={property.tooltip}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Youtube ID</label>
                    <Input
                        onChange={(_, { value }) => updateProperty({ youtubeID: value })}
                        defaultValue={property.youtubeID}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Har filupload?</label>
                    <Checkbox
                        toggle
                        checked={property.hasFileupload}
                        onChange={(_, { checked }) => updateProperty({ hasFileupload: checked })}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Synlighedsformel</label>
                    <FormulaInput
                        placeholder='Efterlad tom, hvis altid synlig'
                        value={property.visibilityFormula}
                        referenceNames={resourceTemplate.properties.map(property => property.tag)}
                        onChange={visibilityFormula => updateProperty({ visibilityFormula })}
                    />
                </Form.Field>
            </>
        );
    };
    
    const renderTypeSpecificOptions = () => {
        // radio questions
        if (property.dataType === dataTypes.ENUM_STRING) {
            const options = property.options || [];
            return (
                <Form.Field>
                    <RadioQuestionEditor
                        basic
                        answers={options}
                        onChange={options => {
                            updateProperty({ options });
                        }}
                    />
                </Form.Field>
            );
        }

        // sub-resources
        if (property.dataType === dataTypes.RESOURCE) {
            const subResourceTemplateSlug = property.subResourceTemplateSlug || '';
            return (
                <Form.Field>
                    <label>Vælg under-ressource</label>
                    <Dropdown
                        selection
                        search
                        defaultValue={subResourceTemplateSlug}
                        onChange={(_, { value }) => updateProperty({ subResourceTemplateSlug: value })}
                        options={allResourceTemplates.map(resource => {
                            return {
                                text: resource.name,
                                value: resource.slug,
                            };
                        })}
                    />
                </Form.Field>
            );
        }

        return null;
    };

    const dataTypeSpecificOptions = renderTypeSpecificOptions();

    return (
        <Modal
            onClose={onClose}
            open={open}
            size='large'
        >
            <Modal.Header>Redigér egenskab: {property.name}</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form style={formStyles}>
                        <Form.Field>
                            <Header>Generelle egenskaber</Header>
                        </Form.Field>
                        {renderGeneralOptions()}
                        {dataTypeSpecificOptions && (
                            <>
                                <Form.Field>
                                    <Header>{dataTypeDescriptions[property.dataType]}-egenskaber</Header>
                                </Form.Field>
                                {dataTypeSpecificOptions}
                            </>
                        )}
                    </Form>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
};


const EditorSegment = ({ title, subtitle, icon, children }) => {
    return (
        <Segment>
            <Header as='h2'>
                <Icon name={icon} />
                <Header.Content>
                    {title}
                    <Header.Subheader>{subtitle}</Header.Subheader>
                </Header.Content>
            </Header>
            {children}
        </Segment>
    );
};

const CreatePropertyPortal = ({ trigger, onCreate, open, onOpen, onClose }) => {
    const [property, setProperty] = useState(cleanProperty());

    const update = mutator => {
        setProperty({ ...property, ...mutator });
    };

    const doCreate = () => {
        if (!property.name) {
            toast.error('Navn er påkrævet');
            return;
        }

        if (!property.tag) {
            toast.error('Tag er påkrævet');
            return;
        }

        if (!property.dataType) {
            toast.error('Datatype er påkrævet');
            return;
        }

        onCreate(property);
        setProperty(cleanProperty());
    };

    const propertyForm = (
        <Form style={formStyles}>
            <Form.Field>
                <label>Navn</label>
                <Input
                    autoFocus
                    placeholder='Navn'
                    defaultValue={property.name}
                    onChange={(_, { value }) => {
                        update({
                            name: value,
                            tag: createSlug(value),
                        });
                    }}
                />
            </Form.Field>
            <Form.Field>
                <label>Tag</label>
                <Input
                    style={{ opacity: 1 }}
                    placeholder='Tag'
                    value={property.tag}
                    disabled
                />
            </Form.Field>
            <Form.Field>
                <label>Datatype</label>
                <Dropdown
                    selection
                    defaultValue={property.dataType}
                    onChange={(_, { value }) => update({ dataType: value })}
                    options={
                        [
                            dataTypes.NUMBER,
                            dataTypes.BOOLEAN,
                            dataTypes.DATE,
                            dataTypes.STRING,
                            dataTypes.ENUM_STRING,
                            dataTypes.RESOURCE,
                        ].map(dtype => ({
                            value: dtype,
                            text: dataTypeDescriptions[dtype],
                        }))
                    }
                />
            </Form.Field>
            <Form.Field>
                <label>Er påkrævet?</label>
                <Checkbox
                    toggle
                    checked={property.required}
                    onClick={() => update({ required: !property.required })}
                />
            </Form.Field>
            <Form.Field>
                <Button
                    primary
                    onClick={doCreate}
                    fluid
                    content='Opret'
                    icon='plus'
                />
            </Form.Field>
        </Form>
    );

    return (
        <Popup
            trigger={trigger}
            content={propertyForm}
            position='top center'
            on='click'
            open={open}
            onOpen={onOpen}
            onClose={onClose}
        />
    );
};

class ResourceTemplatesDetail extends Component {
    state = {
        loading: true,
        error: false,
        allResourceTemplates: [],
        resourceTemplate: null,
        creatingProperty: false,
        propertyBeingEdited: null,
    };

    componentDidMount = async () => {
        try {
            await this.loadResourceTemplate();
        } catch {
            this.setState({ error: true });
        }

        this.setState({ loading: false });
    };

    loadResourceTemplate = async () => {
        const { slug } = this.props.match.params;

        const [allResourceTemplates, resourceTemplate] = await Promise.all([
            productEngine.getResourceTemplates(),
            productEngine.getResourceTemplate(slug),
        ]);

        this.setState({
            resourceTemplate,
            allResourceTemplates,
        });
    };

    saveResourceTemplate = async () => {
        const { history } = this.props;
        const { slug } = this.props.match.params;
        const { resourceTemplate } = this.state;

        // validate visibility formulas
        for (const property of resourceTemplate.properties) {
            if (!property.visibilityFormula) continue;

            const visibilityFormula = property.visibilityFormula?.trim();
            const expressionTree = interpret(visibilityFormula);
            const referencedTags = findAllReferences(expressionTree);

            const unknownReferences = referencedTags.filter(reference => {
                const propertyIsKnown = resourceTemplate.properties.some(property => {
                    return property.tag === reference;
                });

                return !propertyIsKnown;
            });

            if (unknownReferences.length > 0) {
                toast.error(`Fejl i synlighedsformel for "${property.name}": Unkendte reference(r): ${unknownReferences}`);
                return;
            }
        }

        try {
            await productEngine.updateResourceTemplate(slug, resourceTemplate);
            toast.success('Skabelonen blev opdateret!');
            history.push(`/model-editor#Ressourceskabeloner`);
        } catch {
            toast.error('Kunne ikke opdatere ressourceskabelonen...');
        }
    };

    mutateTemplate = (mutator) => {
        const { resourceTemplate } = this.state;

        // do update resource template ...
        mutator(resourceTemplate);

        // ... and save state
        this.setState({ resourceTemplate });
    };

    doUpdateTemplate = (path, value) => {
        this.mutateTemplate(resourceTemplate => {
            lodash.set(resourceTemplate, path, value)
        });
    };
    
    updateTemplate = path => {
        return (_, { value }) => {
            this.doUpdateTemplate(path, value);
        };
    };

    updateText = textKey => {
        return (_, { value }) => {
            this.doUpdateTemplate(`texts.${textKey}`, value);
        };
    };

    addProperty = property => {
        this.mutateTemplate(resourceTemplate => {
            resourceTemplate.properties = resourceTemplate.properties || [];
            resourceTemplate.properties.push(property);
        });

        this.setState({ creatingProperty: false });
    };

    updateProperty = (index, mutator) => {
        this.mutateTemplate(resourceTemplate => {
            for (let [key, value] of Object.entries(mutator)) {
                lodash.set(resourceTemplate.properties[index], key, value);
            }
        });
    };

    deleteProperty = async (index) => {
        const isSure = await confirm('OBS: Kan have konsekvenser for brugere, der allerede har udfyldt egenskaben. Er du sikker?');
        if (!isSure) {
            return;
        }

        this.mutateTemplate(resourceTemplate => {
            resourceTemplate.properties.splice(index, 1);
        })
    };

    swapProperties = (idx1, idx2) => {
        this.mutateTemplate(resourceTemplate => {
            swap(resourceTemplate.properties, idx1, idx2);
        });
    };

    renderHeader = () => {
        const { loading, resourceTemplate } = this.state;

        if (loading) {
            return null;
        }

        return (
            <Segment style={{ position: 'sticky', top: -1, zIndex: 1, padding: 4 }}>
                <Container>
                    <Segment basic style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Grid columns={2} verticalAlign='middle'>
                            <Grid.Column>
                                <Header
                                    icon={resourceTemplate.semanticIcon || 'cog'}
                                    content={resourceTemplate.name}
                                />
                            </Grid.Column>
                            <Grid.Column textAlign='right'>
                                <Button
                                    content='Gem'
                                    icon='save'
                                    color='green'
                                    onClick={this.saveResourceTemplate}
                                />
                            </Grid.Column>
                        </Grid>
                    </Segment>
                </Container>
            </Segment>
        );
    };

    renderMetaForm = () => {
        const { resourceTemplate } = this.state;

        return (
            <Form style={formStyles}>
                <Form.Field>
                    <label>Slug</label>
                    <Input
                        defaultValue={resourceTemplate.slug}
                        style={{ opacity: 1 }}
                        disabled
                        icon='lock'
                    />
                </Form.Field>
                <Form.Field>
                    <label>Internt navn</label>
                    <Input
                        defaultValue={resourceTemplate.name}
                        onChange={this.updateTemplate('name')}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Ikon</label>
                    <IconPicker
                        icon={resourceTemplate.semanticIcon}
                        onChange={icon => this.doUpdateTemplate('semanticIcon', icon)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>
                        Ressourcer skal oprettes pr. skatteår&nbsp;
                        <Tooltip
                            content={
                                <>
                                    <div><b>Slået fra:</b> Brugeren opretter ressourcer én gang og disse bruges pr. skatteår (som i boligudlejning).</div>
                                    <br />
                                    <div><b>Slået til:</b> Brugeren opretter ressourcer forfra pr. skatteår.</div>
                                </>
                            }
                        />
                    </label>
                    <Checkbox
                        toggle
                        checked={resourceTemplate.storeResourcesPerProduct}
                        onChange={(_, { checked }) => this.doUpdateTemplate('storeResourcesPerProduct', checked)}
                    />
                </Form.Field>
            </Form>
        );
    };

    renderTextsEditor = () => {
        const { resourceTemplate } = this.state;

        const example = text => `f.eks. "${text}"`;

        return (
            <Form style={formStyles}>
                <Form.Field>
                    <label>Hovedtitel v. oprettelsesformular</label>
                    <Input
                        defaultValue={resourceTemplate.texts?.mainTitle}
                        onChange={this.updateText('mainTitle')}
                        placeholder={example('Mine boliger')}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Titel v. oprettelse af første ressource</label>
                    <Input
                        defaultValue={resourceTemplate.texts?.createFirst}
                        onChange={this.updateText('createFirst')}
                        placeholder={example('Opret din første bolig her!')}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Titel v. oprettelse af nye ressourcer</label>
                    <Input
                        defaultValue={resourceTemplate.texts?.create}
                        onChange={this.updateText('create')}
                        placeholder={example('Opret bolig')}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Titel v. redigering af eksisterende ressourcer</label>
                    <Input
                        defaultValue={resourceTemplate.texts?.edit}
                        onChange={this.updateText('edit')}
                        placeholder={example('Redigér bolig')}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Tekst der vises når ressourcen er slået fra</label>
                    <Input
                        defaultValue={resourceTemplate.texts?.disabled}
                        onChange={this.updateText('disabled')}
                        placeholder={example('Bolig solgt')}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Vælg tekstegenskab der skal udgøre titlen på ressourcen</label>
                    <Dropdown
                        selection
                        defaultValue={resourceTemplate.titleProperty}
                        placeholder={
                            resourceTemplate.properties.length === 0 ?
                            'Tip: Opret egenskaber for ressourcen af typen "tekst", og vælg én her' :
                            'Vælg tekstegenskab'
                        }
                        options={
                            resourceTemplate
                            .properties
                            .filter(property => property.dataType === dataTypes.STRING)
                            .map(property => ({
                                text: property.name,
                                value: property.tag,
                            }))
                        }
                        onChange={this.updateTemplate('titleProperty')}
                    />
                </Form.Field>
            </Form>
        );
    };

    renderPropertiesEditor = () => {
        const { properties } = this.state.resourceTemplate;

        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Navn</Table.HeaderCell>
                        <Table.HeaderCell>Tag</Table.HeaderCell>
                        <Table.HeaderCell>Datatype</Table.HeaderCell>
                        <Table.HeaderCell>Påkrævet?</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {properties?.map((property, idx) => {                     
                        const { name, tag, dataType, required } = property;

                        const canGoUp = idx !== 0;
                        const canGoDown = idx !== properties.length - 1;
                        return (
                            <Table.Row key={tag}>
                                <Table.Cell>
                                    {name}
                                </Table.Cell>
                                <Table.Cell>
                                    <pre>{tag}</pre>
                                </Table.Cell>
                                <Table.Cell>
                                    {dataTypeDescriptions[dataType]}
                                </Table.Cell>
                                <Table.Cell>
                                    {required ? 'Ja' : 'Nej'}
                                </Table.Cell>
                                <Table.Cell textAlign='right'>
                                    <Icon
                                        link={canGoDown}
                                        color='black'
                                        name='caret square down'
                                        disabled={!canGoDown}
                                        onClick={() => this.swapProperties(idx, idx + 1)}
                                    /> 
                                    <Icon
                                        link={canGoUp}
                                        color='black'
                                        name='caret square up'
                                        disabled={!canGoUp}
                                        onClick={() => this.swapProperties(idx, idx - 1)}
                                    /> 
                                    <Icon
                                        link
                                        color='black'
                                        name='cog'
                                        onClick={() => this.setState({ propertyBeingEdited: property })}
                                    />
                                    <Icon
                                        link
                                        color='red'
                                        name='x'
                                        onClick={() => this.deleteProperty(idx)}
                                    />                                   
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.HeaderCell colSpan={5} textAlign='right'>
                            <CreatePropertyPortal
                                open={this.state.creatingProperty}
                                onOpen={() => this.setState({ creatingProperty: true })}
                                onClose={() => this.setState({ creatingProperty: false })}
                                onCreate={this.addProperty}
                                trigger={
                                    <Button
                                        primary={!this.state.creatingProperty}
                                        content='Opret egenskab'
                                        labelPosition='right'
                                        icon='plus'
                                    />
                                }
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        );
    };

    renderAnnualRolloverValuesEditor = () => {
        const { annualRolloverValues } = this.state.resourceTemplate;
        const seen = new Set();

        return (
            <div>
                <List>
                    {(annualRolloverValues || []).map(({ id, tag }, idx) => {
                        const tagIsBad = tag.length > 0 && !/^[a-z0-9_]+$/i.test(tag);

                        let errMsg;
                        if (tagIsBad) {
                            errMsg = 'Tag må kun bestå af bogstaver fra A-Z, tal og bundstreg';
                        } else if (seen.has(tag)) {
                            errMsg = 'Tagget er allerede defineret';
                        }

                        seen.add(tag);

                        return (
                            <List.Item key={id}>
                                <Input
                                    onChange={(_, { value }) =>
                                        this.mutateTemplate((template) => {
                                            template.annualRolloverValues[idx].tag = value;
                                        })
                                    }
                                    defaultValue={tag}
                                    placeholder='Vælg et tag...'
                                    error={tagIsBad}
                                    fluid
                                    icon={
                                        <Popup
                                            trigger={
                                                <Icon
                                                    onClick={() => this.mutateTemplate(template => template.annualRolloverValues.splice(idx, 1))}
                                                    name='x'
                                                    color='red'
                                                    link
                                                />
                                            }
                                            position='top center'
                                            content='Slet rulningsværdi'
                                        />
                                    }
                                />
                                { errMsg && <Label pointing='above' color='red' content={errMsg} /> }
                            </List.Item>
                        );
                    })}
                </List>
                <div style={{ textAlign: 'right' }}>
                    <Button
                        primary
                        content='Tilføj rulningsværdi'
                        icon='plus'
                        onClick={() => {
                            this.mutateTemplate(template => {
                                template.annualRolloverValues = template.annualRolloverValues || [];
                                template.annualRolloverValues.push({
                                    id: uuid(),
                                    tag: '',
                                });
                            });
                        }}
                    />
                </div>
            </div>
        );
    };

    renderResourceTemplateEditor = () => {
        const { resourceTemplate, propertyBeingEdited } = this.state;

        return (
            <div>
                <EditorSegment title='Metadata' subtitle='Stamdata for ressourcen' icon='dna'>
                    {this.renderMetaForm()}
                </EditorSegment>
                
                <EditorSegment title='Tekster' subtitle='Hjælpetekster til slutbrugeren' icon='text cursor'>
                    {this.renderTextsEditor()}
                </EditorSegment>

                <EditorSegment title='Egenskaber' subtitle='Definér hvilke egenskaber der knytter sig til ressourcen' icon='list' >
                    {this.renderPropertiesEditor()}
                </EditorSegment>

                <EditorSegment title='Værdier til årsrulning' subtitle='Definér talværdier der følger med ressourcen fra år til år' icon='chart bar' >
                    {this.renderAnnualRolloverValuesEditor()}
                </EditorSegment>

                <PropertySettingsEditorModal
                    resourceTemplate={resourceTemplate}
                    allResourceTemplates={this.state.allResourceTemplates}
                    open={!!propertyBeingEdited}
                    property={propertyBeingEdited}
                    onClose={() => this.setState({ propertyBeingEdited: null })}
                    updateProperty={mutator => {
                        const propertyIndex = resourceTemplate.properties.findIndex(p => {
                            return p.tag === propertyBeingEdited.tag;
                        });

                        this.updateProperty(propertyIndex, mutator);
                    }}
                />
            </div>
        );
    };

    renderContent = () => {
        const { error, loading } = this.state;

        if (error) {
            return (
                <Message
                    header='Kunne ikke hente ressourceskabelonen...'
                    error
                />
            );
        }

        if (loading) {
            return (
                <Loader
                    active
                    inline='centered'
                    size='huge'
                />
            );
        }

        return this.renderResourceTemplateEditor();
    };

    render = () => {
        return (
            <div>
                {this.renderHeader()}
                <Container style={{ marginTop: '16px' }}>
                    {this.renderContent()}
                </Container>
            </div>
            
        );
    };
}

export default ResourceTemplatesDetail;