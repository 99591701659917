import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Dropdown, Icon, Input, Modal, Pagination, Popup, Table } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import uuid from 'uuid/v4';
import productEngine from 'http/productEngine';
import { consumeQueryParam } from 'shared/queryParams';
import DatePicker from 'atoms/DatePicker';
import InputSection, { InputSectionRow } from './InputSection';
import model, { inputDataTypes, taxoTypeMap } from '../model';
import TaxonomyTagInput from '../TaxonomyTagInput';

const RawInput = ({ onChange, value, ...props }) => {
    return <Input
        {...props}
        defaultValue={value}
        onChange={(_, { value }) => onChange(value)}
    />;
};

const NumberInput = ({ value, valueLastYear, terms, onChange, onLastYearChange, onTermsChange, tag, ...props }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const inputVariations = [
        [
            'Active year',
            value,
            onChange,
            { paddingRight: '2px' },
        ],
        [
            'Last year',
            valueLastYear,
            onLastYearChange,
            { paddingLeft: '2px' },
        ],
    ];

    const currentTerms = terms || [];

    const updateTermAtIndex = (idx, mutator) => {
        const termsCopy = [...currentTerms];
        mutator(termsCopy[idx]);
        onTermsChange(termsCopy);
    };

    const deleteTermAtIndex = (idx) => {
        const termsCopy = [...currentTerms];
        termsCopy.splice(idx, 1);
        onTermsChange(termsCopy);
    };

    const termRows = currentTerms.map(
        ({ id, text, currentYearAmount, lastYearAmount }, idx) => {
            return (
                <Table.Row key={id}>
                    <Table.Cell width={7}>
                        <Input
                            placeholder='Account text...'
                            fluid
                            defaultValue={text}
                            onChange={(_, { value }) =>
                                updateTermAtIndex(idx, (term) => {
                                    term.text = value;
                                })
                            }
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <Input
                            placeholder='Current year amount...'
                            type='number'
                            fluid
                            defaultValue={currentYearAmount}
                            onChange={(_, { value }) =>
                                updateTermAtIndex(idx, (term) => {
                                    term.currentYearAmount = Number(value);
                                })
                            }
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <Input
                            placeholder='Last year amount...'
                            type='number'
                            fluid
                            defaultValue={lastYearAmount}
                            onChange={(_, { value }) =>
                                updateTermAtIndex(idx, (term) => {
                                    term.lastYearAmount = Number(value);
                                })
                            }
                        />
                    </Table.Cell>
                    <Table.Cell width={1}>
                        <Icon
                            name='x'
                            link
                            onClick={() => deleteTermAtIndex(idx)}
                        />
                    </Table.Cell>
                </Table.Row>
            );
        }
    );
    
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 1 }}>
                {inputVariations.map(
                    ([placeholder, startValue, changeHandler, style]) => {
                        return (
                            <div
                                style={{
                                    display: 'inline-block',
                                    width: '50%',
                                    ...style,
                                }}
                            >
                                <RawInput
                                    {...props}
                                    placeholder={placeholder}
                                    value={startValue}
                                    onChange={changeHandler}
                                    type='number'
                                />
                            </div>
                        );
                    }
                )}
            </div>
            <div
                style={{
                    textAlign: 'center',
                }}
            >
                <Modal
                    onClose={() => setModalOpen(false)}
                    open={modalOpen}
                    trigger={
                        <Popup
                            content='Edit terms'
                            position='top center'
                            trigger={
                                <Icon
                                    style={{ marginLeft: '1em' }}
                                    onClick={() => setModalOpen(true)}
                                    name='list ol'
                                    link
                                />
                            }
                        />
                    }
                    closeIcon
                >
                    <Modal.Header>
                        <Icon name='list ol' /> Edit terms for {tag}
                    </Modal.Header>
                    <Modal.Content>
                        {termRows?.length > 0 && (
                            <Table basic='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            Text
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Current year amount
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Last year amount
                                        </Table.HeaderCell>
                                        <Table.HeaderCell />
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>{termRows}</Table.Body>
                            </Table>
                        )}
                        <div style={{ textAlign: 'right' }}>
                            <Button
                                primary
                                content='Add term'
                                icon='plus'
                                onClick={() =>
                                    onTermsChange([
                                        ...currentTerms,
                                        {
                                            id: uuid(),
                                            text: '',
                                            currentYearAmount: 0,
                                            lastYearAmount: 0,
                                        },
                                    ])
                                }
                            />
                        </div>
                    </Modal.Content>
                </Modal>
            </div>
        </div>
    );
};

const BooleanInput = ({ onChange, value, ...props }) => {
    const yes = 'yes';
    const no = 'no';
    return <Dropdown
        {...props}
        selection
        options={[
            {
                text: 'No',
                value: no,
            },
            {
                text: 'Yes',
                value: yes,
            },
        ]}
        value={value === true ? yes : (value === false ? no : null)}
        onChange={(_, { value }) => onChange(value === yes)}
    />;
};

const ListInput = ({ onChange, value, ...props }) => {
    return <RawInput
        {...props}
        onChange={value => onChange(value.split(',').map(x => x.trim()).filter(x => x))}
        value={Array.isArray(value) ? value.join() : ''}
    />;
};

const ResourceInput = ({ onSubDefinitionsChange, subDefinitions, onResourceTemplateSlugChange, resourceTemplateSlug }) => {
    const [modelOpen, setModalOpen] = useState(false);
    const [resourceTemplates, setResourceTemplates] = useState(null);

    useEffect(() => {
        productEngine.getResourceTemplates()
            .then(templates => setResourceTemplates(templates))
            .catch(err => toast.error(`Failed to get resource templates: ${err.message}`));
    }, []);

    const modal = modelOpen && (
        <Modal open onClose={() => setModalOpen(false)} size='fullscreen' closeIcon>
            <Modal.Header>
                Define resource input definitions
            </Modal.Header>
            <Modal.Content>
                <TaxonomyInputEditor
                    inputDefinitions={subDefinitions || []}
                    setInputDefinitions={onSubDefinitionsChange}
                    taxonomy={{}}
                />
            </Modal.Content>
        </Modal>
    );

    const resourceSelector = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Dropdown
                placeholder='Choose a template'
                style={{ flex: 1 }}
                selection
                search
                fluid
                disabled={!resourceTemplates}
                loading={!resourceTemplates}
                defaultValue={resourceTemplateSlug}
                onChange={(_, { value }) => onResourceTemplateSlugChange(value)}
                options={(resourceTemplates || []).map(template => {
                    return {
                        text: template.name,
                        value: template.slug,
                    };
                })}
            />
            <div style={{ whiteSpace: 'nowrap', marginLeft: '1em' }}>
                <Popup
                    position='top center'
                    content='Settings'
                    trigger={
                        <Icon
                            onClick={() => setModalOpen(true)}
                            name='cog'
                            link
                        />
                    }
                />
            </div>
        </div>
    );

    return (
        <>
            {modal}
            {resourceSelector}
        </>
    );
};

const dataTypeComponentMap = {
    [model.definitionDataTypes.String]:     RawInput,
    [model.definitionDataTypes.Number]:     NumberInput,
    [model.definitionDataTypes.Boolean]:    BooleanInput,
    [model.definitionDataTypes.StringList]: ListInput,
    [model.definitionDataTypes.Date]:       DatePicker,
    [model.definitionDataTypes.Resource]:   ResourceInput,
};

const InputRow = ({ testValue, testValueLastYear, terms, subDefinitions, resourceTemplateSlug, tag, dataType, isTaxoTag, onChange, onDelete, autoFocus }) => {
    const TestValueComponent = dataTypeComponentMap[dataType] || RawInput;

    const handleResourceTemplateSlugChange = async resourceTemplateSlug => {
        const bootstrapResourceSubDefinitions = async (templateSlug, seen = new Set()) => {
            if (seen.has(templateSlug)) {
                throw new Error(`Resource template infinite loop on template: ${templateSlug}`);
            }

            seen.add(templateSlug);

            const template = await productEngine.getResourceTemplate(templateSlug);

            const subDefinitions = await Promise.all(template.properties.map(async ({ tag, dataType, subResourceTemplateSlug }) => {
                const subDefition = {
                    tag,
                    dataType,
                };

                if (subResourceTemplateSlug) {
                    subDefition.resourceTemplateSlug = subResourceTemplateSlug
                    subDefition.subDefinitions = await bootstrapResourceSubDefinitions(subResourceTemplateSlug);
                }

                return subDefition;
            }))
            
            return subDefinitions;
        };

        onChange({
            resourceTemplateSlug,
            subDefinitions: await bootstrapResourceSubDefinitions(resourceTemplateSlug),
        });
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '0.5em' }}>
            <div style={{ width: '32%', marginRight: '1em' }}>
                <TaxonomyTagInput
                    fluid
                    defaultValue={tag}
                    onChange={(_, { value }) => onChange({ tag: value })}
                    placeholder='Input tag'
                    autoFocus={autoFocus}
                    icon={isTaxoTag && <Icon name='check circle' color='green' />}
                />
            </div>
            <div style={{ width: '32%', marginRight: '1em' }}>
                <Dropdown
                    selection
                    search  
                    fluid
                    placeholder='Select a data type'
                    value={dataType}
                    onChange={(_, { value }) => onChange({ dataType: value })}
                    options={inputDataTypes.map(({ id, description, icon }) => {
                        return {
                            text: description,
                            value: id,
                            icon: icon,
                            key: id,
                        };
                    })}
                />
            </div>
            <div style={{ width: '32%', marginRight: '1em' }}>
                <TestValueComponent
                    fluid
                    disabled={!dataType}
                    value={testValue}
                    valueLastYear={testValueLastYear}
                    terms={terms}
                    subDefinitions={subDefinitions}
                    resourceTemplateSlug={resourceTemplateSlug}
                    tag={tag}
                    onChange={value => onChange({ testValue: value })}
                    onLastYearChange={value => onChange({ testValueLastYear: value })}
                    onTermsChange={value => onChange({ terms: value })}
                    onSubDefinitionsChange={value => onChange({ subDefinitions: value })}
                    onResourceTemplateSlugChange={handleResourceTemplateSlugChange}
                    placeholder='Test value'
                />
            </div>
            <div style={{ width: '4%', textAlign:'center' }}>
                <Icon
                    onClick={onDelete}
                    name='x'
                    link
                />
            </div>
        </div>
    );
};

const TaxonomyInputEditor = ({ inputDefinitions, setInputDefinitions, taxonomy }) => {
    const [filterQuery, setFilterQuery] = useState(consumeQueryParam('definition'));
    const [inputCreatedSignal, setInputCreatedSignal] = useState(false);
    const [page, setPage] = useState(0);
    const definitionsRef = useRef();
    const searchFieldRef = useRef();

    const createNewInputDefinition = () => {
        setInputDefinitions([
            {
                id: uuid(),
                tag: '',
                dataType: '',
                required: false,
            },
            ...inputDefinitions,
        ]);
        setPage(0);
        setFilterQuery('');
        setInputCreatedSignal(true);
        searchFieldRef.current.inputRef.current.value = '';
    };

    useLayoutEffect(() => {
        if (!inputCreatedSignal) {
            return;
        }

        const container = definitionsRef.current;
        container.scrollTo(0, container.scrollHeight);
        setInputCreatedSignal(false);
    }, [inputCreatedSignal]);

    useEffect(() => setPage(0), [filterQuery]);

    const setDataAtIndex = (idx, toUpdate = {}) => {
        const copy = [...inputDefinitions];

        const pickedTaxonomyTag = taxonomy[toUpdate.tag];
        if (pickedTaxonomyTag) {
            const corrospondingDataType = taxoTypeMap[pickedTaxonomyTag.type];
            if (corrospondingDataType) {
                toUpdate.dataType = corrospondingDataType;
            }
        }

        if (toUpdate.dataType) {
            toUpdate.testValue = null;
            toUpdate.testValueLastYear = null;
        }

        Object.entries(toUpdate).forEach(([key, val]) => {
            copy[idx][key] = val;
        });

        setInputDefinitions(copy);
    };

    const deleteAtIndex = idx => {
        const copy = [...inputDefinitions];
        copy.splice(idx, 1);
        setInputDefinitions(copy);
    };

    const rowsJSX = inputDefinitions.map(({ id, tag, testValue, testValueLastYear, terms, subDefinitions, resourceTemplateSlug, ...rest }, idx) => {
        const isTaxonomyTag = tag in taxonomy;

        if (filterQuery) {
            const queryLower = filterQuery.toLowerCase();
            const valuesToCheck = [
                tag,
                testValue,
                testValueLastYear,
            ].map(v => v?.toString()?.toLowerCase() || '');

            if (!valuesToCheck.some(str => str?.includes(queryLower))) {
                return null;
            }
        }

        return (
            <InputRow
                {...rest}
                key={id}
                taxonomy={taxonomy}
                tag={tag}
                testValue={testValue}
                testValueLastYear={testValueLastYear}
                terms={terms}
                subDefinitions={subDefinitions}
                resourceTemplateSlug={resourceTemplateSlug}
                isTaxoTag={isTaxonomyTag}
                onChange={updator => setDataAtIndex(idx, updator)}
                onDelete={() => deleteAtIndex(idx)}
                autoFocus={inputCreatedSignal}
            />
        );
    }).filter(row => row);
   
    const pageSize = 10;
    const totalPages = Math.ceil(rowsJSX.length / pageSize);
    const pagesToShow = rowsJSX.slice(pageSize * page, pageSize * page + pageSize);

    return (
        <InputSection>
            <InputSectionRow>
                <Input
                    ref={searchFieldRef}
                    icon='search'
                    iconPosition='left'
                    placeholder='Filter input definitions...'
                    onChange={(_, { value }) => setFilterQuery(value)}
                    defaultValue={filterQuery}
                    autoFocus
                />
            </InputSectionRow>
            {pagesToShow.length > 0 &&
                <InputSectionRow ref={definitionsRef}>
                    {pagesToShow}
                </InputSectionRow>
            }
            <InputSectionRow>
                <div style={{ display: 'inline-block', width: '50%' }}>
                    <Pagination
                        activePage={page + 1}
                        totalPages={totalPages}
                        onPageChange={(_, { activePage }) => setPage(activePage - 1)}
                    />
                </div>
                <div style={{ display: 'inline-block', width: '50%', textAlign: 'right' }}>
                    <Button
                        primary
                        icon='plus'
                        content='New input'
                        onClick={createNewInputDefinition}
                    />
                </div>
            </InputSectionRow>
        </InputSection>
    );
};

export default TaxonomyInputEditor;