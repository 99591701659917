const extractPreviewText = html => {
    const doc =  new DOMParser().parseFromString(html, 'text/html');

    const extractBodyText = (node, output = { buffer: '' }) => {
        const style = node.style || {};
        const isHeader = Number((style['font-size'] || '').replace('px', '')) >= 20;
        const isHidden = style['display'] === 'none';
        const shouldSkipNode = isHeader || isHidden;
        if (shouldSkipNode) {
            return;
        }

        if (node.nodeType === Node.TEXT_NODE) {
            output.buffer += node.textContent;
        }

        for (const child of node.childNodes) {
            extractBodyText(child, output);
        }

        return output.buffer;
    };

    const fullText = extractBodyText(doc.body);

    return (
        fullText
        .split('\n')
        .map(x => x.trim())
        .filter(x => x)
        .join('\n')
    );
};

export default extractPreviewText;