import React from 'react';

class NodeDataSumInputs extends React.Component {
    getData () {
        return {
            flip: this.flip.checked || false,
            round: this.round.checked || false,
        };
    }

    render () {
        const { flip, round } = this.props.node.data;
        return (
            <>
                <div>
                    <label>
                        Vend fortegn 
                        <input type='checkbox' defaultChecked={flip} ref={flip => this.flip = flip}/>
                    </label>
                </div>
                <div>
                    <label>
                        Afrund resultat
                        <input type='checkbox' defaultChecked={round} ref={round => this.round = round}/>
                    </label>
                </div>
            </>
        );
    }
}

export default NodeDataSumInputs;