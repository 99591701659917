import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';


/**
 * @param {*} WrappedComponent 
 * @param {(props: *) => Promise} fetchHandler 
 */
export const withRESTResource = (WrappedComponent, fetchHandler) => {
    const wrapped = class extends Component {
        state = {
            data: null,
            loading: true,
            error: false,
            working: false,
        };

        componentDidMount = async () => {
            try {
                await this.fetchResource();
            } catch (e) {
                this.setState({ error: e });
            } finally {
                this.setState({ loading: false });
            }
        };

        fetchResource = async () => {
            const data = await fetchHandler(this.props);
            this.setState({ data });
        };

        setLocalData = (data = {}) => {
            this.setState({
                data: {
                    ...this.state.data,
                    ...data,
                },
            });
        };

        refetch = async () => {
            this.setState({ working: true, error: false });
            try {
                await this.fetchResource();
            } catch (e) {
                this.setState({ error: e });
            } finally {
                this.setState({ working: false });
            }
        };

        render = () => {
            return (
                <WrappedComponent
                    refetch={this.refetch}
                    rest={this.state}
                    setLocalRestData={this.setLocalData}
                    {...this.props}
                />
            );
        };
    };

    return withRouter(wrapped);
};