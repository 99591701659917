import React, { Component } from 'react';
import { Segment, Header, Form, Message, Button, Dropdown, Loader, Checkbox } from 'semantic-ui-react';
import modelEditor from 'http/modelEditor';
import payment from 'http/payment';
import taxonomyParserClient from 'http/taxonomyParser';
import withNavShell from '../../molecules/withNavShell';
import './ProductsMetadata.css';
import Tooltip from 'atoms/Tooltip';

const DEFAULT_TAXONOMI = '';
/**
 * The value -1 represents an ML version that is undefined. No ML version in the database has version equal to -1.
 * 
 * It is a number since the request with the ML version has to pass through services developed in staticly typed languages. e.g. Go.
 */
const DEFAULT_ML_VERSION = -1;

class ProductsMetadata extends Component {
    state = {
        loading: true,
        metadata: {},
        taxonomyVersions: [],
        models: [],
        mlVersions: [],
        mlError: null,
        saasProducts: [],
    };

    componentDidMount () {
        this.performRequest(async () => {
            try {
                await Promise.all([
                    this.readModelMetadata(),
                    this.readAllModels(),
                    this.readSaasProducts(),
                ]);
            } catch (e) {
                this.setState({ error: 'Kunne ikke hente metadata...' });
                return;
            }

            try {
                await this.readAllMLVersions();
            } catch (e) {
                this.setState({ mlError: e });
            }
            
            try {
                await this.readTaxonomyVersions();
            } catch (e) {
                this.setState({ error: 'Kunne ikke hente taxonomi versioner...' });
                return;
            }
            this.props.updateNavShell();
        });
    }

    setLoading = loading => new Promise(r => this.setState({ loading }, r));

    performRequest = async func => {
        await this.setLoading(true);
        await func();
        await this.setLoading(false);
    };

    readModelMetadata = async () => {
        const modelId = this.props.match.params.modelId;
        const metadata = await modelEditor.getModelMetadata(modelId);
        this.setState({ metadata });
    };

    readAllModels = async () => {
        const models = await modelEditor.getModels();
        this.setState({ models });
    };

    readSaasProducts = async () => {
        const saasProducts = await payment.getSAASProducts();
        this.setState({ saasProducts });
    };

    readTaxonomyVersions = async () => {
        const taxonomyVersions = await taxonomyParserClient.getTaxonomyVersions();
        this.setState({ taxonomyVersions });
    };

    readAllMLVersions = async () => {
        const mlVersions = (await modelEditor.getMLVersions());
        this.setState({ mlVersions });
    }

    saveModel = async () => {
        this.performRequest(async () => {
            try {
                const { modelId } = this.props.match.params;
                const { metadata } = this.state;
                await modelEditor.patchModelMetadata(modelId, metadata);
                this.props.history.push(`/model-editor/${modelId}/nodes`);
            } catch (e) {
                this.setState({ error: 'Kunne ikke gemme modellen...' });
            }
        });
    };

    setModelVal = prop => {
        const { metadata } = this.state;
        return e => {
            metadata[prop] = e.target.value;
            this.setState({ metadata });
        };
    };

    setModelValDropdown = prop => {
        const { metadata } = this.state;
        return (a, { value }) => {
            metadata[prop] = value;
            this.setState({ metadata });
        };
    };

    getTaxonomyVersionDropdownOptions = () => {
        const { taxonomyVersions } = this.state;
        const defaultOption = {
            key: DEFAULT_TAXONOMI,
            value: DEFAULT_TAXONOMI,
            text: '-'
        };
        const taxonomyVersionOptions = taxonomyVersions.map(taxonomyVersion => {
            const { id, displayName } = taxonomyVersion;
            return {
                key: id,
                value: id,
                text: displayName
            };
        });
        return [ defaultOption, ...taxonomyVersionOptions ];
    }

    getMLVersionDropdownOptions = () => {
        const { mlVersions } = this.state;
        const defaultOption = {
            key: DEFAULT_ML_VERSION,
            value: DEFAULT_ML_VERSION,
            text: `Brug standardversion`
        };
        const mlVersionOptions = mlVersions.map(mlVersion => {
            const { version_number } = mlVersion;
            return {
                key: version_number,
                value: version_number,
                text: `${version_number}`
            };
        });
        return [ defaultOption, ...mlVersionOptions ];
    }

    getModelDropdownOptions = () => {
        const { models } = this.state;
        return models.map(({ id, name }) => {
            return {
                key: id,
                value: id,
                text: `${name} (${id})`
            };
        });
    };

    renderFormField = (label, prop, attrs = {}) => {
        const defaultValue = this.state.metadata[prop];
        return (
            <Form.Field>
                <label>{label}</label>
                <input
                    onChange={this.setModelVal(prop)}
                    defaultValue={defaultValue}
                    {...attrs}
                />
            </Form.Field>
        );
    };

    renderDropdown = (title, options, value, prop, otherProps = {}) => {
        return (
            <Form.Field>
                <label>{title}</label>
                <Dropdown
                    fluid
                    value={value}
                    search
                    selection
                    options={options}
                    onChange={this.setModelValDropdown(prop)}
                    clearable
                    {...otherProps}
                />
            </Form.Field>
        );
    };

    renderTaxonomyRelatedOptions = () => {
        const options = this.getTaxonomyVersionDropdownOptions();
        const taxonomy  = this.state.metadata.taxonomy;
        return (
            <>
                {this.renderDropdown(`Taksonomiversion`, options, taxonomy, 'taxonomy')}
                {taxonomy && (
                    <Form.Field>
                        <Checkbox
                            toggle
                            label='Specificér perioder manuelt for noder med taksonomikonfiguration?'
                            checked={this.state.metadata.specifyTaxonomyPeriodsManually}
                            onChange={(_, { checked }) => {
                                this.setState({
                                    metadata: {
                                        ...this.state.metadata,
                                        specifyTaxonomyPeriodsManually: checked,
                                    },
                                });
                            }}
                        />
                        &nbsp;
                        <Tooltip
                            content={`
                                Hvis nej, bruges brugerens regnskabsperiode.
                                Hvis ja, skal perioder relateret til taksonomidata selv specificeres.
                            `}
                        />
                    </Form.Field>
                )}
            </>
        );
    };

    renderMLVersionDropdown = () => {
        const options = this.getMLVersionDropdownOptions();
        const mlVersion = this.state.metadata.mlVersion;
        return this.renderDropdown(`ML version`, options, mlVersion, 'mlVersion', { error: this.state.mlError });
    };

    renderPrevModelDropdown = () => {
        const { previousModel } = this.state.metadata;
        const options = this.getModelDropdownOptions();
        return this.renderDropdown('Primo-model', options, previousModel, 'previousModel');
    };

    renderSaasProductsDropdown = () => {
        const options = this.state.saasProducts.map(product => {
            return {
                text: product.descriptiveName,
                key: product.id,
                value: product.id,
            };
        });
        return this.renderDropdown('Regnskabsklasse', options, this.state.metadata.stripeID, 'stripeID');
    };

    renderProductKindDropdown = () => {
        const options = ['Årsrapport', 'Selvangivelse', 'Forskudsopgørelse', 'ESG'].map(productKind => {
            return {
                text: productKind,
                key: productKind,
                value: productKind,
            };
        });

        return this.renderDropdown('Type af model', options, this.state.metadata.kind, 'kind');
    };

    renderContent = () => {
        const { error, loading } = this.state;

        if (error) {
            return (
                <Message
                    negative
                    content={error}
                    icon='warning'
                />
            );
        }

        if (loading) {
            return (
                <Loader
                    inline='centered'
                    active
                />
            );
        }

        return (
            <Form className='products-metadata-form'>
                {this.renderFormField('Model ID', 'id', { disabled: true })}
                {this.renderFormField('Modelnavn', 'name')}
                {this.renderFormField('Skatteår', 'year', { type: 'number' })}
                {this.renderSaasProductsDropdown()}
                {this.renderTaxonomyRelatedOptions()}
                {this.renderProductKindDropdown()}
                {this.renderMLVersionDropdown()}
                {this.renderPrevModelDropdown()}
                <Button
                    primary
                    content='Gem'
                    icon='save'
                    loading={loading}
                    onClick={this.saveModel}
                />
            </Form>
        );
    };

    render () {
        return (
            <Segment style={{ margin: '1em' }}>
                <Header size='large'>Metadata</Header>
                {this.renderContent()}
            </Segment>
        );
    }
}

export default withNavShell(ProductsMetadata);
